import React, {useRef, useState, useEffect} from 'react';
import { PageLayout } from '../components/page-layout';




const PublicPage = () => {


  return (
    <PageLayout className="bg-image">
    <section>
    <div className="row">
        <div className="col-xl-7 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat">
        </div>
        <div className="col-xl-5 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
                <h2 className="h2_log">Bienvenido a DazAlert</h2>
                <p className="p_log">
                    En DazAlert, nos especializamos en alertar en diferentes tipos de divisas, incluyendo forex y el mercado cripto, a través de algoritmos e inteligencia artificial. Nuestros indicadores propios te avisan si la tendencia marca long o short, lo que te permite tomar decisiones informadas y tener buenas entradas en trading. Con nuestras alertas personalizadas, recibirás mensajes instantáneos en tu dispositivo móvil o en nuestra app en tiempo real. Optimizamos el tiempo que cada suscriptor le dedica al trading para que puedas sacar el máximo provecho a tu tiempo.
                </p>
            </div>
        </div>
      </div>

    </section>
    </PageLayout>

  );
};

export default PublicPage;
