import React, { useRef, useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from 'moment';
import 'moment/locale/es'; // Import Spanish locale
import Load from '../components/Load';
import Header from '../components/Header';
import Appfooter from '../components/Appfooter';


// Set the locale globally
moment.locale('es');

// Register necessary Chart.js components and the zoom plugin
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, zoomPlugin, TimeScale);

const Grafica = () => {
  const chartRef = useRef(null);
  const [data, setData] = useState([]);
  const [labels, setlabels] = useState([]);
  const [prices, setprices] = useState([]);
  const [invercion, setinvercion] = useState([]);
  const [BTCS, setBTC] = useState([]);
  const [winnings, setWinnings] = useState(0); 
  const [btcAcum, setBTCAcum] = useState(0);
  const [totalInversion, setTotalInversion] = useState(0); 
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({
    interaction: {
      mode: 'index', // Enables tooltip display
      intersect: false, // Allows tooltips to overlap
    },
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
        pan: {
          enabled: true,
          mode: 'xy',
        },
      },
    },
    transitions: {
      zoom: {
        animation: {
          duration: 1000,
          easing: 'easeOutCubic'
        }
      }
    },
    scales: {
      x: {
        title:{
          display: true,
          text: "Fecha"
        },
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'dd MMM yyyy', // Customize the date format here
          },
          locale: 'es', // Set the locale to Spanish
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 20,
        },
      },
      y: {
        title:{
          display: true,
          text: "Precio en USD ($)"
        }
      }
    },
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'DCA Bitcoin',
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('http://dazapis.herokuapp.com/get_data_BTC');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const fetchedData = await response.json();

            // Sort the fetched data based on the timestamp column
      fetchedData.sort((a, b) => {
        return new Date(a.timestamp) - new Date(b.timestamp);
      });

      setData(fetchedData);
      setLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // Only proceed if data is available
    if (data.length > 0) {
      // Extract labels and data from fetched data
      const labels1 = data.map(dataPoint => moment(dataPoint.timestamp).format('YYYY-MM-DD')); // Parse timestamps into moment objects
      const prices = data.map(dataPoint => dataPoint.precio);
      const invercion = data.map(dataPoint => dataPoint.invercion);
  
      // Calculate BTC array, replacing NaN and Infinity with 0
      const BTC = prices.map((price, index) => {
        const inv = parseFloat(invercion[index]);
        if (isNaN(inv) || !isFinite(inv)) {
            return 0;
        } else {
          const result = inv / price;
          return parseFloat(result.toFixed(7));
        }
      });

            // Calculate sum of inversion and sum of BTC
      const sumInversion = invercion.reduce((acc, curr) => {
        const inv = parseFloat(curr);
        return isNaN(inv) || !isFinite(inv) ? acc : acc + inv;
      }, 0);

      const sumBTC = BTC.reduce((acc, curr) => acc + curr, 0);

      // Get the last price
      const lastPrice = prices[prices.length - 1];

      // Calculate the expression
    
      const winningsValue = ((lastPrice * sumBTC) - sumInversion).toFixed(2);
      const sumeInversionTot = sumInversion.toFixed(2)
  
      setlabels(labels1)
      setprices(prices)
      setBTC(BTC)
      setBTCAcum(sumBTC)
      setinvercion(invercion)
      setWinnings(winningsValue);
      setTotalInversion(sumeInversionTot);

      // Update options with new data
      setOptions(prevOptions => ({
        ...prevOptions,
        scales: {
          ...prevOptions.scales,
          x: {
            ...prevOptions.scales.x,
            time: {
              unit: 'day',
            },
          },
        },
      }));
    }
  }, [data]);
  
  const handleResetZoom = () => {
    setOptions(prevOptions => ({
      ...prevOptions,
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day',
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 20,
          },
        },
      },
      y: {
        title:{
          display: true,
          text: "Precio en USD ($)"
        }
      },
      x: {
        title:{
          display: true,
          text: "Fecha"
        }
      },
      transitions: {
        zoom: {
          animation: {
            duration: 1000,
            easing: 'easeOutCubic'
          }
        }
      }
    }));
  };

  // Data for the chart
  const datas = {
    labels,
    datasets: [
      {
        label: 'Precio de Bitcoin',
        data: prices,
        fill: false,
        borderColor: '#0000FF',
        backgroundColor: '#0000FF',
        pointBackgroundColor: '#0000FF',
        tension: 0.1,
      },
      {
        label: 'Inversión',
        data: invercion,
        fill: false,
        borderColor: 'red',
        backgroundColor: 'red',
        pointBackgroundColor: 'red',
        tension: 0.1,
      },
      {
        label: 'Cantidad de Bitcoin comprado',
        data: BTCS,
        fill: false,
        borderColor: 'green',
        backgroundColor: 'green',
        pointBackgroundColor: 'green',
        tension: 0.1,
      },
    ],
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px', // Add margin top for spacing
  };
  

  const buttonStyle = {
    padding: '8px 20px',
    marginLeft: '0px',
    backgroundColor: '#1600FF',
    color: '#fff',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
};

const dataBoxesStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginBottom: '20px',
};

const dataBoxStyle = {
  margin: '10px',
  border: '1px solid #ccc',
  borderRadius: '5px',
  padding: '10px',
  textAlign: 'center',
};

const mediaQueryStyle = {
  '@media screen and (max-width: 768px)': {
    dataBoxesStyle: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    dataBoxStyle: {
      width: '90%',
    },
  },
};


  return (
    <section>
        <Header/>
      <div className="main-content   pt-7 right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
          {loading && (
            <Load/>
          )}
          {!loading && (
            <div>
              <div style={dataBoxesStyle}>
                <div style={dataBoxStyle}>
                  <h1> Inversión total: ${totalInversion} </h1>
                </div>
                <div style={dataBoxStyle}>
                  <h1> Ganancias: ${winnings}</h1>
                </div>
                <div style={dataBoxStyle}>
                 <h1> BTC Acumulado: {btcAcum.toFixed(5)}</h1>
                </div>
              </div>
              <Line data={datas} options={options} ref={chartRef} />
              <div style={buttonContainerStyle}>
                <button style={buttonStyle}  onClick={handleResetZoom}>Regresar a gráfica original</button>
              </div>
            </div>
          )}
         
        </div>
        </div>
      </div>
      <Appfooter />
    </section>
  );
};

export default Grafica;
