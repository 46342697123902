import React, {  useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Header from "../components/Header";
import Appfooter from "../components/Appfooter";
import { Link } from "react-router-dom";
function Notification() {
  const [alerts, setAlerts] = useState([]);
  const [userTickers, setUserTickers] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [noAlert, setNoAlert] = useState(false)

  const { user } = useAuth0();
  const { email } = user;
  const USERURL = "https://dazapis.herokuapp.com/get_sub_type"
  const USERURL1 = "https://dazapis.herokuapp.com/get_sub_amount"
  const ALERTSURL = "https://dazapis.herokuapp.com/get_alerta"

  useEffect(() => {
    const params = new URLSearchParams({ 'nombre_usuario': email });

    axios.get(`${USERURL}?${params.toString()}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    .then(res => {
      //console.log(res.data)
        if (new Date(res.data.fechacorte) >= new Date()) {
            setIsSubscribed(true);
            axios.get(`${USERURL1}?${params.toString()}`, {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            })
            .then(res => {
                setUserTickers(res.data.tickers);
                fetchAlerts(res.data.tickers);

            });
        }else {
          setNoAlert(true)
        }
    });
  }, [email]);

  const fetchAlerts = (tickers) => {
    fetch(`${ALERTSURL}?tickers=${tickers.join(',')}`)
      .then((res) => res.json())
      .then((data) => {
        //("SAVED")
        setAlerts(data);
      
      })
      .catch((error) => {
       // console.log("Error fetching data:", error);
      });
  };


  //console.log(alerts)
  return (
    <section>
      <Header />
        <div className="main-content theme-dark-bg pt-7 right-chat-active">
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="chat-wrapper p-3 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                      <h2 className="fw-700 mb-4 mt-2 font-md text-grey-900 d-flex align-items-center">
                        Alertas
                        <span className="circle-count text-white font-xsssss rounded-3 ms-2 ls-3 fw-600 p-2  mt-0">
                        
                        </span>
                        
                        
                      </h2>

                      <ul className="notification-box">
                      {
                        noAlert ? 
                        <div>
                        <h3>Consigue una suscripción para recibir alertas</h3>
                        <Link
                            activeclassname="active"
                            to="/paquetes"
                            className="update-cart bg-warning float-left text-white fw-600 text-uppercase font-xssss border-dark border rounded-3 border-size-md d-inline-block w500 p-3 text-center ls-3"
                                                            >
                            <h4 class="fw-400 font-xsss mb-0 mt-0">Conseguir una suscripción</h4>
                          
                          </Link>
                          
                        </div>
                        :
                        alerts.map((alert, index) => (
                          <li key={index}>
                            <div
                              className={`d-flex align-items-center p-3 rounded-3 ${alert.texto}`}
                            >
                              <img
                              src={`assets/images/bell.svg`}
                              alt="user"
                              className="w45 me-3"
                              />
                              <i
                              className={`text-white me-2 font-xssss notification-react ${alert.exchange}`}
                              ></i>
                              <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-500 lh-20">
                                <strong>{alert.ticker}</strong> Precio: $ 
                                {alert.precio} → {alert.texto}
                                <span className="d-block text-grey-500 font-xssss fw-600 mb-0 mt-0 0l-auto">
                                  {" "}
                                  {new Date(alert.time).toLocaleString()}
                                </span>{" "}
                              </h6>
                            
                            </div>
                          </li>
                        ))
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <Appfooter />
    </section>
  );
}

export default Notification;
