import React from "react";
import { PageLayout } from "../components/page-layout";
import Header from "../components/Header";

const NotFoundPage = () => {
  return (
    <section>
      <Header></Header>
      <div class="main-content pt-0 ps-0 pe-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8 text-center default-page vh-100 align-items-center d-flex">
              <div class="card border-0 text-center d-block p-0">
                <i className="feather-alert-circle w200 mb-4 ms-auto me-auto pt-md-5 large-icon"></i>
                <h1 class="fw-700 text-grey-900 display3-size display4-md-size">¡Ups! Parece que estás perdido.</h1>
                <p class="text-grey-500 font-xsss">La página que estás buscando no está disponible. Intente buscar nuevamente</p>
                <a href="/about" class="p-3 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-3 text-uppercase ls-3">Página de inicio</a>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  );
};
export default NotFoundPage