

import Header from "../components/Header";
import Appfooter from "../components/Appfooter";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
import axios from 'axios';
import { Alert } from "react-bootstrap";


const Suscripciones = () => {
       
    const {user} = useAuth0();
    const { name, picture, email } = user;


    const [users, setUsers] = useState([]);
    const [usersStudio, setUsersStudio] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchTerm2, setSearchTerm2] = useState("");
    const [searchTerm3, setSearchTerm3] = useState("");
    const [matchUsers, setMatchUsers] = useState([]);

    const MATCHUSERURL = "https://dazapis.herokuapp.com/getmatchusers"; // Just an example URL
    const ACTIVESUBSCRIBERURL = "https://dazapis.herokuapp.com/getActiveSubscribers";
    const ACTIVESUBSCRIBERURLSTUDIO = "https://dazapis.herokuapp.com/getActiveSubscribersStudio";

        useEffect(() => {
            const fetchUsersData = async () => {
                try {
                    // Fetch active subscribers
                    const activeSubscribersResponse = await axios.get(ACTIVESUBSCRIBERURL);
                    setUsers(activeSubscribersResponse.data);

                    // Fetch active subscribers Studio
                    const activeSubscribersResponseStudio = await axios.get(ACTIVESUBSCRIBERURLSTUDIO);
                    setUsersStudio(activeSubscribersResponseStudio.data);
        
                    // Fetch matched users
                    const matchUsersResponse = await axios.get(MATCHUSERURL);
                    setMatchUsers(matchUsersResponse.data);
        
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            };
        
            fetchUsersData();
        }, []);
    

        const handlePagoVerificado = (email, suscription) => {
            // Make API call to handle verification
            axios.post("https://dazapis.herokuapp.com/verifyPaymentSub", { email, suscription })
            .then(response => {
                // Fetch active subscribers
                axios.get(ACTIVESUBSCRIBERURL)
                .then(activeSubscribersResponse => {
                    setUsers(activeSubscribersResponse.data);
                })
                .catch(error => {
                    console.error("Error fetching active subscribers:", error);
                });

                // Fetch active subscribers
                axios.get(ACTIVESUBSCRIBERURLSTUDIO)
                .then(activeSubscribersResponseStudio => {
                    setUsersStudio(activeSubscribersResponseStudio.data);
                })
                .catch(error => {
                    console.error("Error fetching active subscribers:", error);
                });
        
                // Fetch matched users
                axios.get(MATCHUSERURL)
                .then(matchUsersResponse => {
                    setMatchUsers(matchUsersResponse.data);
                })
                .catch(error => {
                    console.error("Error fetching match users:", error);
                });
        
                // handle response
                alert("Se ha verificado la suscripción del usuario correctamente")
            })
            .catch(error => {
                console.error("Error verifying payment:", error);
            });
        }
        
        const handlePagoIncorrecto = (email) => {
            // Make API call to notify user about incorrect payment
            axios.post("https://dazapis.herokuapp.com/incorrectPayment", { email })
            .then(response => {
                // Fetch active subscribers
                axios.get(ACTIVESUBSCRIBERURL)
                .then(activeSubscribersResponse => {
                    setUsers(activeSubscribersResponse.data);
                })
                .catch(error => {
                    console.error("Error fetching active subscribers:", error);
                });

                // Fetch active subscribers
                axios.get(ACTIVESUBSCRIBERURLSTUDIO)
                .then(activeSubscribersResponseStudio => {
                    setUsersStudio(activeSubscribersResponseStudio.data);
                })
                .catch(error => {
                    console.error("Error fetching active subscribers:", error);
                });
        
                // Fetch matched users
                axios.get(MATCHUSERURL)
                .then(matchUsersResponse => {
                    setMatchUsers(matchUsersResponse.data);
                })
                .catch(error => {
                    console.error("Error fetching match users:", error);
                });
        
                // handle response
                alert("Se ha cancelado la confirmación para suscripción del usuario")
            })
            .catch(error => {
                console.error("Error sending incorrect payment notification:", error);
            });
        }

    //console.log(matchUsers)
    //console.log(searchTerm)
   // console.log(users)


    return (
        <view>
       
        <section>
        <Header />
        <div className="main-content pt-7 right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                            <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">Suscriptores por verificar
                                <form action="#" class="pt-0 pb-0 ms-auto">
                                <div class="search-form-2 ms-2">
                                    <i class="ti-search font-xss"></i>
                                     {/* 2. Update the search term when the input field value changes. */}
                                        <input 
                                            type="text" 
                                            className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0" 
                                            placeholder="Buscar aquí." 
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                </div>
                                </form>
                                
                                </h2>
                            </div>
                            <div class="row ps-2 pe-2">
                         {
        matchUsers.filter(mUser => mUser.email.toLowerCase().includes(searchTerm.toLowerCase())).map(user => (
            <div className="col-md-6 col-sm-4 pe-2 ps-2" key={`${user.email}-${user.created_at}`}>
                <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                    <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                        <div className="clearfix w-100"></div>
                        <h4 className="fw-700 font-xsss mt-0 mb-3">{user.email}</h4>
                        <h4 className="fw-500 font-xsss mt-0 mb-1">{user.suscription}</h4>
                        <div className="fw-500 font-xsss mt-0 mb-1" >Fecha de pago: {user.created_at}</div>
                        { user.image 
                            ? <img src={`data:image/png;base64,${user.image}`} alt="Payment proof" style={{ width: '250px', height: '250px' }} /> 
                            : <div className="fw-500 font-xsss mt-0 mb-1">Codigo Txid: {user.codigotix}</div> 
                        }
                        <div className="mt-2">
                            <button onClick={() => handlePagoVerificado(user.email,user.suscription)} className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white">Pago Verificado</button>
                            <button onClick={() => handlePagoIncorrecto(user.email)} className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-danger font-xsssss fw-700 ls-lg text-white">Pago Incorrecto</button>
                        </div>
                    </div>
                </div>
            </div>
        ))
    }
    <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                            <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">Suscriptores en DazAlert
                                <form action="#" class="pt-0 pb-0 ms-auto">
                                <div class="search-form-2 ms-2">
                                    <i class="ti-search font-xss"></i>
                                     {/* 2. Update the search term when the input field value changes. */}
                                        <input 
                                            type="text" 
                                            className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0" 
                                            placeholder="Buscar aquí." 
                                            value={searchTerm2}
                                            onChange={(e) => setSearchTerm2(e.target.value)}
                                        />
                                </div>
                                </form>
                                
                                </h2>
                            </div>

                    {
                        users.filter(mUser => mUser.email.toLowerCase().includes(searchTerm2.toLowerCase())).map(user => (
                        <div key={user.email} className="col-md-5 col-sm-6 pe-2 ps-2">
                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                                <div className="card-body d-block w-100 p-4 text-center">
                                    <h4 className="fw-700 font-xss mt-3 mb-0">{user.email}</h4>
                                    <div>Fecha de corte: {user.fechacorte}</div>
                                    <div>Suscripción: 
                                        {user.code === 465 ? " 1 mes ilimitado" :
                                         user.code === 884 ? " 1 año ilimitado" :
                                         user.code === 374 ? " 1 mes limitado" :
                                         user.code === 987 ? " 1 año limitado" :
                                         user.code === 23 ? " Prueba Gratuita" : "Código no reconocido"}
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    ))
                    
                        
                    }
                                
                                
                </div>
            </div>
            <div class="row">
                        <div class="col-xl-12">
                            <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                            <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">Suscriptores DazAlert Estudio
                                <form action="#" class="pt-0 pb-0 ms-auto">
                                <div class="search-form-2 ms-2">
                                    <i class="ti-search font-xss"></i>
                                     {/* 2. Update the search term when the input field value changes. */}
                                        <input 
                                            type="text" 
                                            className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0" 
                                            placeholder="Buscar aquí." 
                                            value={searchTerm3}
                                            onChange={(e) => setSearchTerm3(e.target.value)}
                                        />
                                </div>
                                </form>
                                
                                </h2>
                            </div>
                            <div class="row ps-2 pe-2">

                    {
                        usersStudio.filter(mUser => mUser.email.toLowerCase().includes(searchTerm3.toLowerCase())).map(user => (
                        <div key={user.email} className="col-md-5 col-sm-6 pe-2 ps-2">
                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                                <div className="card-body d-block w-100 p-4 text-center">
                                    <h4 className="fw-700 font-xss mt-3 mb-0">{user.email}</h4>
                                </div>
                            </div>
                        </div>
                    ))
                    
                        
                    }
                                
                       </div>         
                </div>
            </div>
         </div>
         </div>
         </div>
         </div>
        <Appfooter />
        </section>
        </view>
    )
}

export default Suscripciones
