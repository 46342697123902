import React from 'react';

const Table = ({ data, handleEdit }) => {
  return (
    <div style={{ marginTop: '20px' , marginLeft: '50px', marginRight: '50px', marginBottom: '20px'}}>
      <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #ddd' }}>
        <thead>
          <tr>
            <th style={{ padding: '8px', backgroundColor: '#f2f2f2', border: '1px solid #ddd', textAlign: 'center' }}>Fecha</th>
            <th style={{ padding: '8px', backgroundColor: '#f2f2f2', border: '1px solid #ddd', textAlign: 'center' }}>Precio de Bitcoin</th>
            <th style={{ padding: '8px', backgroundColor: '#f2f2f2', border: '1px solid #ddd', textAlign: 'center' }}>Inversión</th>
            <th style={{ padding: '8px', backgroundColor: '#f2f2f2', border: '1px solid #ddd', textAlign: 'center' }}>Acción</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>{row.timestamp}</td>
              <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>{row.precio}</td>
              <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>{row.invercion}</td>
              <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>
                <button style={{ padding: '5px 10px', backgroundColor: '#4caf50', color: 'white', border: 'none', cursor: 'pointer', borderRadius: '3px' }} onClick={() => handleEdit(index)}>Editar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
