

import Header from "../components/Header";
import Appfooter from "../components/Appfooter";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { Button } from "react-bootstrap";

const CambioPage = () => {

    const {user} = useAuth0();
    const [inputValues, setInputValues] = useState({});
    const [prices, setPrices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [codeInput, setCodeInput] = useState('');
    const getPrices = "https://dazapis.herokuapp.com/getprices";
    const getCodigo = "https://dazapis.herokuapp.com/getcode";
    const changeCode = "https://dazapis.herokuapp.com/changecode";
    const getQR = "https://dazapis.herokuapp.com/getqr";
    const newQR = "https://dazapis.herokuapp.com/newqr";
    const [codigo, setCodigo] = useState('');
    const [newCodigo, setNewCodigo] = useState('');
    const [qrImage, setQrImage] = useState(null);
    const [qrImage2, setQrImage2] = useState(null);


    useEffect(() => {
        const fetchPrices = async () => {
            setLoading(true);
            try {
                const response = await axios.get(getPrices);
                setPrices(response.data);
                // Initialize inputValues state
                let initialValues = {};
                response.data.forEach((price) => {
                    initialValues[price.id] = { value: price.precio, changed: false };
                });
                setInputValues(initialValues);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        fetchPrices();
    }, []);


    useEffect(() => {
        // Function to fetch the codigo from the SQL table
        const fetchCodigo = async () => {
          try {
            // Replace this with your actual database fetching logic
            const response = await axios.get(getCodigo);
            setCodigo(response.data.codigo);
            //console.log(response.data.codigo) // Set the fetched codigo into the state
          } catch (error) {
            console.error('Error fetching codigo:', error);
          }
        };
    
        fetchCodigo();
      }, []); // The empty array ensures this effect runs only once after the initial render
    
      useEffect(() => {
        const fetchQrImage = async () => {
            try {
                const response = await axios.get(getQR);
    
                // Check if the response contains the QR image data
                if (response.data.qr_image) {
                    setQrImage(response.data.qr_image);
                } else {
                    //console.log('No QR image found');
                }
            } catch (error) {
                console.error('Error fetching QR image:', error);
                // Handle the error appropriately
            }
        };
    
        fetchQrImage();
    }, []);

    const handleInputChange = (id, newValue) => {
        setInputValues({
            ...inputValues,
            [id]: { value: newValue, changed: newValue !== '' && !isNaN(newValue) }
        });
    };

    
    const updatePrice = async (id) => {
        if (inputValues[id].changed) {
            try {
                const newPrice = inputValues[id].value;
                await axios.post('https://dazapis.herokuapp.com/updatePrice', { id, newPrice });
    
                //console.log('Updated price for id:', id, 'with value:', newPrice);
    
                // Option 1: Refetch prices to update the UI
                // fetchPrices();
                //console.log("DONE")
                // Option 2: Update the local state (if you're not refetching from the server)
                setPrices(prices.map(price => price.id === id ? { ...price, precio: newPrice } : price));
                
                // Reset the changed flag
                setInputValues({
                    ...inputValues,
                    [id]: { ...inputValues[id], changed: false }
                });
    
            } catch (error) {
                console.error('Error updating price:', error);
                // Handle the error appropriately
            }
        }
    };

    const handleCodeInputChange = (e) => {
        setNewCodigo(e.target.value);
    };

    const changeCodigo = async () => {
        try {
            await axios.post(changeCode, new URLSearchParams({ new_codigo: newCodigo }));
            // Optionally, you can fetch the updated codigo value and update the state
            const response = await axios.get(getCodigo);
            setCodigo(response.data.codigo);
            // Clear the input field after successful change
            setNewCodigo('');
            //('Codigo changed successfully');
        } catch (error) {
            console.error('Error changing codigo:', error);
            // Handle the error appropriately
        }
    };

    const handleQrImageChange = (e) => {
        const file = e.target.files[0];
        //console.log('Imagen seleccionada:', file);
        setQrImage2(file);

    };

    const uploadQrImage = async () => {
        try {
            const formData = new FormData();
            formData.append('qr_image', qrImage2);

            await axios.post(newQR, formData);

            //console.log('QR image uploaded successfully');

            
              // Fetch the updated QR image
              const response = await axios.get(getQR);
              // Set the updated QR image in the state
              setQrImage(response.data.qr_image);
              setQrImage2(null);
              // Optionally, you can also clear the input file
              document.getElementById('fileInput').value = '';

        } catch (error) {
            console.error('Error uploading QR image:', error);
            // Handle the error appropriately
            const response = await axios.get(getQR);
            setQrImage(response.data.qr_image);
        }
    };

    
    
//console.log(codigo)


    return (
        <section>
        <Header />
            <div className="main-content pt-7 right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                <div class="row">
                    <div class="col-lg-12">
                    <div class="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                        <div class="row">
                        <div class="col-lg-6 ps-lg-5">
                            <h2 class="display1-size d-block mb-2 text-grey-900 fw-700">Cambio en códigos de pago</h2>
                            <p class="font-xssss fw-500 text-grey-500 lh-26">Planes de membresía anual y mensual</p>
                        </div>
                        </div>
                    </div>
                    </div>

                <div class="card w-100 border-0 shadow-none bg-transparent mt-5">
                <div data-accordion-component="Accordion" class="accodion-style--1 accordion">

                    {prices.map((price, index) => (

                    <div data-accordion-component="AccordionItem" className="shadow-xss" key={price.id}>
                    <div data-accordion-component="AccordionItemHeading" className="card-header" role="heading" aria-level="3">
                        <div id={`accordion__heading-${price.nombre}`} aria-disabled="false" aria-expanded="false" aria-controls={`accordion__panel-${index}`} role="button" tabIndex="0" className="accordion__button" data-accordion-component="AccordionItemButton">

                        </div>
                    
                            <div data-accordion-component="AccordionItemPanel" class="card-body" role="region" aria-labelledby="accordion__heading-4" id="accordion__panel-4">
                            <div class="product-action flex-row align-items-center">
                            
                            <div key={index} className=" w-100 border-0 shadow-none bg-transparent mt-5">
                                <p>
                                    <span >Precio de:</span> 
                                    <span style={{ fontWeight: 'bold' }} > {price.nombre} </span>
                                    <input 
                                        type="number"
                                        placeholder={price.precio}
                                        value={inputValues[price.id]?.value || ''}
                                        onChange={(e) => handleInputChange(price.id, e.target.value)}
                                    />
                                    <span>$</span>
                                    <Button 
                                    className="bg-dark text-white fw-700 ps-lg-5 pe-lg-5 text-uppercase font-xssss float-right border-dark border rounded-3 border-size-md d-inline-block mt-0 p-2 text-center ls-3"
                                    onClick={() => updatePrice(price.id)}
                                    disabled={!inputValues[price.id]?.changed}
                                    > Cambiar el precio
                                    </Button>
                                </p>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
        
                        ))}


                </div>
                </div>

                <div data-accordion-component="AccordionItem" className="shadow-xss" >
                    <div data-accordion-component="AccordionItemHeading" className="card-header" role="heading" aria-level="3">
                        <div id={`accordion__heading-${user}`} aria-disabled="false" aria-expanded="false" aria-controls={`accordion__panel`} role="button" tabIndex="0" className="accordion__button" data-accordion-component="AccordionItemButton">

                        </div>
                    
                            <div data-accordion-component="AccordionItemPanel" class="card-body" role="region" aria-labelledby="accordion__heading-4" id="accordion__panel-4">
                            <div class="product-action flex-row align-items-center">
                            
                            <div className=" w-100 border-0 shadow-none bg-transparent mt-5">
                                <p>
                                    <span >QR de la billetera actual:</span> 
                                    {qrImage && (
                                        <img
                                            src={`data:image/png;base64, ${qrImage}`}
                                            alt="QR Code"
                                            style={{ width: '300px', height: '300px', marginBottom: '10px' }}
                                        />
                                    )}
                                    <input 
                                        type="file"
                                        accept="image/*"
                                        id="fileInput"
                                        onChange={handleQrImageChange}
                                        style={{ marginBottom: '10px' }}
                                    />
                                    <Button
                                        className="bg-dark text-white fw-700 ps-lg-5 pe-lg-5 text-uppercase font-xssss float-right border-dark border rounded-3 border-size-md d-inline-block mt-0 p-2 text-center ls-3"
                                        onClick={uploadQrImage}
                                        disabled={!qrImage2}
                                    >
                                        Subir imagen QR
                                    </Button>
                                </p>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                <div>
                <div data-accordion-component="AccordionItem" className="shadow-xss" >
                    <div data-accordion-component="AccordionItemHeading" className="card-header" role="heading" aria-level="3">
                        <div id={`accordion__heading-${user}`} aria-disabled="false" aria-expanded="false" aria-controls={`accordion__panel`} role="button" tabIndex="0" className="accordion__button" data-accordion-component="AccordionItemButton">

                        </div>
                    
                            <div data-accordion-component="AccordionItemPanel" class="card-body" role="region" aria-labelledby="accordion__heading-4" id="accordion__panel-4">
                            <div class="product-action flex-row align-items-center">
                            
                            <div className=" w-100 border-0 shadow-none bg-transparent mt-5">
                                <p>
                                    <span >Código para la billetera actual:</span> 
                                    <input 
                                        placeholder={codigo}
                                        value={newCodigo}
                                        onChange={handleCodeInputChange}
                                        style={{width: '500px', marginBottom: '10px'}}
                                    />
                                    <Button 
                                    className="bg-dark text-white fw-700 ps-lg-5 pe-lg-5 text-uppercase font-xssss float-right border-dark border rounded-3 border-size-md d-inline-block mt-0 p-2 text-center ls-3"
                                    onClick={changeCodigo}
                                    disabled={!newCodigo || newCodigo === codigo}>
                                         Cambiar el código
                                    </Button>
                                </p>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
        
                </div>
                </div>
                </div>
            </div>
            </div>
            <Appfooter />
        </section>
     
    )
}

export default CambioPage
