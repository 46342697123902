import React from "react";
import { NavLink } from "react-router-dom";

export const MobileNavBarBrand = ({ handleClick }) => {

  const getLogoSource = () => {
    const {theme} = localStorage;

    //console.log("theme")
    //console.log(theme)
    if (theme === "theme-dark") {
      return "assets/images/DazLogo_2.png";
    } else if (theme === "theme-light") {
      return "assets/images/DazLogo_1.png";
    }
    // Return a default logo image source if the theme is not recognized
    return "assets/images/DazLogo_2.png";
  };

  return (
    <div onClick={handleClick} className="mobile-nav-bar__brand">
      <NavLink to="/">
        <img
          className="mobile-nav-bar__logo"
          src={getLogoSource()}
          alt="Daz Alert Logo"
          //width="220"
          //height="123"
          width={110}
          height={60}
          //width="82"
          //height="24"
        />
      </NavLink>
    </div>
  );
};
