import React from "react";
import { Link } from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";

const Appfooter = () => { 

  const auth0 = useAuth0();
  const {user} = auth0;
  const {  picture} = user;

    return (
      <div className="app-footer border-0 shadow-lg bg-primary-gradiant">
        <Link to="/about" className="nav-content-bttn nav-center">
          <i className="feather-home"></i>
        </Link>
        <Link to="/notificacion" className="nav-content-bttn">
          <i className="feather-bell"></i>
        </Link>
        {/* <Link to="/defaultlive" className="nav-content-bttn" data-tab="chats">
          <i className="feather-layout"></i>
        </Link> */}
        <Link to="/paquetes" className="nav-content-bttn">
          <i className="feather-package"></i>
        </Link>
        <Link to="/defaultsettings" className="nav-content-bttn">
          <img
            src={picture}
            alt="user"
            className="w30 shadow-xss"
          />
        </Link>
      </div>
    );

  
}

export default Appfooter;
