import React,{Component} from 'react';

class Load extends Component {
    componentDidMount() {
        // Start the video after 3 seconds (3000 milliseconds)
        setTimeout(() => {
          const video = document.getElementById('myVideo');
          if (video) {
            video.play();
          }
        }, 3000);
      }
    render() {
        return (

            <div className="card w-100 h-100 card w-100 h-100 d-flex align-items-center justify-content-center ">
                <video 
                 id ="myVideo"
                  className="video" 
                  autoPlay 
                  loop 
                  muted  
                  style={{ width: '50%', height: '50%' }}>
                <source src="assets/video/load.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
                </video>
            </div>

        );
    }
}

export default Load;