import React, { useRef, useState, useEffect } from 'react';
import { PageLayout } from '../components/page-layout';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from 'moment';
import 'moment/locale/es'; // Import Spanish locale
import Table from './Table';
import Popup from './Popup';
import { da } from 'date-fns/locale';
import Header from '../components/Header';
import Appfooter from '../components/Appfooter';

// Set the locale globally
moment.locale('es');

// Register necessary Chart.js components and the zoom plugin
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, zoomPlugin, TimeScale);

const BTC = () => {
  const chartRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [showPopup, setShowPopup] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [data, setData] = useState([]);
  const [newEntryData, setNewEntryData] = useState({
    timestamp: moment().format('MM/DD/YY'), // Initialize timestamp to today's date
    precio: '0', 
    invercion: '0', 
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('http://dazapis.herokuapp.com/get_data_BTC');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const fetchedData = await response.json();
      setData(fetchedData);

          // Sort the fetched data based on the timestamp column
      fetchedData.sort((a, b) => {
        return new Date(a.timestamp) - new Date(b.timestamp);
    });

    setData(fetchedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const saveData = async (editedData) => {
    try {
      const response = await fetch('http://dazapis.herokuapp.com/save_data_BTC', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editedData),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      fetchData(); // Fetch data again to refresh the table after saving
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const editData = async (editedData) => {
    //console.log("EDITED IN SAVE: ", editedData)
    try {
      const response = await fetch(`http://dazapis.herokuapp.com/edit_data_BTC`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editedData),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      fetchData(); // Fetch data again to refresh the table after editing
    } catch (error) {
      console.error('Error editing data:', error);
    }
  };

  // Logic to calculate index of the last row on current page
  const indexOfLastRow = currentPage * rowsPerPage;
  // Logic to calculate index of the first row on current page
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  // Logic to get current rows
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleEdit = (indexOnPage) => {
    const indexInData = indexOfFirstRow + indexOnPage; // Calculate index in the entire dataset
    setEditIndex(indexInData);
    setShowPopup(true);
  };
  

  const handleClosePopup = () => {
    setShowPopup(false);
    setEditIndex(null);

        // Reset newEntryData state to initial values
    setNewEntryData({
      timestamp: moment().format('MM/DD/YY'),
      precio: '0',
      invercion: '0',
    });
  };

  const handleSavePopup = async (updatedData) => {
    setNewEntryData(updatedData);
    //("UpdatedData: ", updatedData.precio, updatedData.invercion, updatedData.timestamp, updatedData.id);
    //console.log("Entering handleSavePopup");
  
    try {
      if (editIndex !== null) {
        //console.log("Editing existing data...");
        const newData = [...data];
        //console.log("NEW DATA BEFORE MERGE", newData[editIndex])
        //console.log("NEW entry data: ", updatedData)
        newData[editIndex] = { ...newData[editIndex], ...updatedData }; // Merge the existing data with the edited data
        //console.log("New data after merge:", newData[editIndex]);
        await editData(newData[editIndex]); // Pass the merged data to the editData function
      } else {
       // console.log("Adding new data...");
        //console.log("New entry data:", updatedData);
        await saveData(updatedData);
      }
  
      //console.log("Fetching data...");
      await fetchData(); // Fetch data again to refresh the table after saving or editing
  
      //console.log("Closing popup and resetting edit index...");
      setShowPopup(false);
      setEditIndex(null);

            // Reset newEntryData state to initial values
      setNewEntryData({
        timestamp: moment().format('MM/DD/YY'),
        precio: '0',
        invercion: '0',
      });
  
      // Show success alert
      alert("Datos guardados exitosamente!");
  
    } catch (error) {
      console.error("Error saving or editing data:", error);
      // Show error alert
      alert("Se ha producido un error guardando los datos, porfavor intente.");
    }
  
    //("Exiting handleSavePopup");
  };
  

  const handleAddNewEntry = () => {
    // Check if the timestamp is empty, then update it with today's date
    const todayDate = moment().format('MM/DD/YY');
    const newTimestamp = newEntryData.timestamp || todayDate;
  
    setNewEntryData({ 
      timestamp: newTimestamp,
      precio: '0', 
      invercion: '0', 
      new: 'si'
    });
    setShowPopup(true);
  };
  

  const handleDelete = async () => {
    try {
        if (editIndex !== null) {
            const response = await fetch(`http://dazapis.herokuapp.com/delete_data_BTC/${data[editIndex].id}`, {
                method: 'DELETE'
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const newData = [...data];
            newData.splice(editIndex, 1); // Remove the row from the data array
            setData(newData); // Update the state with the modified data

            setShowPopup(false); // Close the popup after deleting
            setEditIndex(null); // Reset the edit index

            // Show success alert
            alert("Datos eliminados exitosamente!");
        }
    } catch (error) {
        console.error("Error deleting data:", error);
        // Show error alert
        alert("Se ha producido un error eliminando los datos, por favor inténtelo de nuevo.");
    }
};


  return (
      <section>
        <Header/>
         <div className="main-content  pt-7 right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
          <button onClick={handleAddNewEntry} style={{ backgroundColor: '#28a745', color: '#fff', border: 'none', borderRadius: '5px', marginRight:'50px', padding: '5px 10px', cursor: 'pointer' }}>
            + Agregar nueva entrada
          </button>
        </div>
        <Table data={currentRows} handleEdit={handleEdit} />
        {/* Pagination */}
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          {Array.from({ length: Math.ceil(data.length / rowsPerPage) }, (_, i) => (
            <button key={i} style={{ margin: '5px 5px', padding: '5px 10px', borderRadius: '5px', backgroundColor: currentPage === i + 1 ? '#007bff' : '#e9ecef', color: currentPage === i + 1 ? '#fff' : '#000', border: '1px solid #ccc' }} onClick={() => paginate(i + 1)}>
              {i + 1}
            </button>
          ))}
        </div>
        {/* Popup */}
        {showPopup && (
          <section>
          <Popup
            rowData={editIndex !== null ? data[editIndex] : newEntryData}
            onSave={handleSavePopup}
            onClose={handleClosePopup}
            onDelete={() => handleDelete()}
          />
          </section>
        )}
        </div>
        </div>
        </div>
        <Appfooter />
      </section>
  );
};

export default BTC;
