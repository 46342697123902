import React, { Component, Fragment } from "react";

import Header from "../components/Header";
import Footer from "../components/Appfooter"

//import Friends from "../components/Friends";
//import Contacts from "../components/Contacts";

//import Createpost from "../components/Createpost";

//import Friendsilder from "../components/Friendsilder";
//import Storyslider from "../components/Storyslider";
//rev


class About extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <div class="main-content pt-7 right-chat-active">
          <div class="middle-sidebar-bottom">
            <div class="middle-sidebar-left"><div class="row justify-content-center">
              <div class="col-xl-10">
                <div class="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center" />
                  <div class="card-body p-md-5 p-4 text-center" style={{backgroundColor: 'rgba(0, 85, 255, 0.8)'}}>
                    <h2 class="fw-700 display2-size text-white display2-md-size lh-2">Bienvenido a DazAlert!</h2>
                      <p class="font-xsss ps-lg-5 pe-lg-5 lh-28 text-grey-200">¡Únete a DazAlert y obtén alertas personalizadas de trading en tiempo real!</p>
                </div>
              </div>
              <div class="card w-100 border-0 shadow-none bg-transparent mt-5">
                <div data-accordion-component="Accordion" class="accodion-style--1 accordion">
                  <div data-accordion-component="AccordionItem" class="card shadow-xss">
                    <div data-accordion-component="AccordionItemHeading" class="card-header" role="heading" aria-level="3">
                      <div id="accordion__heading-4" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-4" role="button" tabindex="0" class="accordion__button" data-accordion-component="AccordionItemButton">
                        <h5 class="fw-600 pt-2 pb-2 mb-0">Que hacemos?</h5>
                      </div>
                    </div>
                    <div data-accordion-component="AccordionItemPanel" class="card-body" role="region" aria-labelledby="accordion__heading-4" id="accordion__panel-4" hidden="">
                      <p>En DazAlert, nos especializamos en alertar en diferentes tipos de divisas, incluyendo forex y el mercado cripto, a través de algoritmos e inteligencia artificial. Nuestros indicadores propios te avisan si la tendencia marca long o short, lo que te permite tomar decisiones informadas y tener buenas entradas en trading. Con nuestras alertas personalizadas, recibirás mensajes instantáneos en tu dispositivo móvil o en nuestra app en tiempo real. Optimizamos el tiempo que cada suscriptor le dedica al trading para que puedas sacar el máximo provecho a tu tiempo.</p>
                    </div>
                    </div>
                    <div data-accordion-component="AccordionItem" class="card shadow-xss">
                      <div data-accordion-component="AccordionItemHeading" class="card-header" role="heading" aria-level="3">
                        <div id="accordion__heading-5" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-5" role="button" tabindex="0" class="accordion__button" data-accordion-component="AccordionItemButton">
                          <h5 class="fw-600 pt-2 pb-2 mb-0">¿Por qué unirse a DazAlert?</h5>
                        </div>
                      </div>
                    <div data-accordion-component="AccordionItemPanel" class="card-body" role="region" aria-labelledby="accordion__heading-5" id="accordion__panel-5" hidden="">
                      <p><span style={{ fontWeight: 'bold' }}>Alertas personalizadas en tiempo real:</span> nuestras alertas se adaptan a tus preferencias de trading y te avisan en tiempo real.</p>
                      <p><span style={{ fontWeight: 'bold' }}>Indicadores propios:</span> nuestros algoritmos e inteligencia artificial han sido desarrollados por expertos en el mercado de divisas y criptomonedas.</p>
                      <p><span style={{ fontWeight: 'bold' }}>Máximo provecho a tu tiempo:</span> optimizamos el tiempo que dedicas al trading para que puedas aprovechar cada oportunidad.</p>
                      <p><span style={{ fontWeight: 'bold' }}>Fácil de usar:</span> nuestra aplicación es fácil de usar y puedes personalizar tus alertas según tus necesidades.</p>
                      <p><span style={{ fontWeight: 'bold' }}>Soporte de expertos:</span> nuestro equipo de soporte está siempre disponible para ayudarte a maximizar tus ganancias.</p>
                      <p><span style={{ fontWeight: 'bold', color:'black' }}>¿Listo para comenzar a obtener ganancias en trading? Únete a DazAlert hoy y comienza a recibir alertas personalizadas en tiempo real.</span></p>
                    </div>
                  </div>
                  <div data-accordion-component="AccordionItem" class="card shadow-xss">
                    <div data-accordion-component="AccordionItemHeading" class="card-header" role="heading" aria-level="3">
                      <div id="accordion__heading-6" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-6" role="button" tabindex="0" class="accordion__button" data-accordion-component="AccordionItemButton">
                        <h5 class="fw-600 pt-2 pb-2 mb-0">Misión</h5>
                      </div>
                    </div>
                    <div data-accordion-component="AccordionItemPanel" class="card-body" role="region" aria-labelledby="accordion__heading-6" id="accordion__panel-6" hidden="">
                      <p>Nuestra misión en DazAlert es proporcionar alertas personalizadas y precisas para que los traders puedan tomar decisiones informadas en el mercado de divisas y criptomonedas, optimizando su tiempo y maximizando sus ganancias.</p>
                    </div>
                  </div>
                  <div data-accordion-component="AccordionItem" class="card shadow-xss">
                    <div data-accordion-component="AccordionItemHeading" class="card-header" role="heading" aria-level="3">
                      <div id="accordion__heading-7" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-7" role="button" tabindex="0" class="accordion__button" data-accordion-component="AccordionItemButton">
                        <h5 class="fw-600 pt-2 pb-2 mb-0">Visión</h5>
                      </div>
                    </div>
                  <div data-accordion-component="AccordionItemPanel" class="card-body" role="region" aria-labelledby="accordion__heading-7" id="accordion__panel-7" hidden="">
                    <p>Nuestra visión es convertirnos en el proveedor líder de alertas personalizadas y precisas en el mercado de divisas y criptomonedas, ayudando a los traders a alcanzar sus objetivos financieros y mejorar su calidad de vida.</p>
                  </div>
                </div>
                <div data-accordion-component="AccordionItem" class="card shadow-xss">
                    <div data-accordion-component="AccordionItemHeading" class="card-header" role="heading" aria-level="3">
                      <div id="accordion__heading-7" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-7" role="button" tabindex="0" class="accordion__button" data-accordion-component="AccordionItemButton">
                        <h5 class="fw-600 pt-2 pb-2 mb-0">Meta</h5>
                      </div>
                    </div>
                  <div data-accordion-component="AccordionItemPanel" class="card-body" role="region" aria-labelledby="accordion__heading-7" id="accordion__panel-7" hidden="">
                    <p>Nuestra meta en DazAlert es ayudar a nuestros suscriptores a convertirse en traders exitosos, proporcionándoles herramientas y recursos de alta calidad para mejorar su estrategia de trading y aumentar su rentabilidad.</p>
                  </div>
                </div>
                <div data-accordion-component="AccordionItem" class="card shadow-xss">
                    <div data-accordion-component="AccordionItemHeading" class="card-header" role="heading" aria-level="3">
                      <div id="accordion__heading-7" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-7" role="button" tabindex="0" class="accordion__button" data-accordion-component="AccordionItemButton">
                        <h5 class="fw-600 pt-2 pb-2 mb-0">Nota*</h5>
                      </div>
                    </div>
                  <div data-accordion-component="AccordionItemPanel" class="card-body" role="region" aria-labelledby="accordion__heading-7" id="accordion__panel-7" hidden="">
                    <p>En DazAlert, entendemos que el trading puede ser una actividad emocionante pero también riesgosa, especialmente si eres un trader principiante. Es por eso que ofrecemos a nuestros suscriptores acceso a una cuenta virtual con $100,000 dólares virtuales para que puedan aprender a hacer operaciones, controlar su riesgo y sentimientos, y mejorar su estrategia de trading sin correr el riesgo de perder dinero real. Creemos que esta cuenta virtual es una herramienta valiosa para que nuestros suscriptores adquieran experiencia y confianza en su habilidad para hacer trading antes de arriesgar su propio dinero.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
        <Footer/>
      </Fragment>
    );
  }
}

export default About;
