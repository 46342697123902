import Header from "../components/Header";
import { useState } from "react";
import Appfooter from "../components/Appfooter";
const Help = () => {


    const [searchTerm, setSearchTerm] = useState("");


    const questions = [
        {
            title: "¡Comunícate con nosotros al correo de soporte de DazAlert!",
            content: "Email: <a href='mailto:support@dazalert.com'>support@dazalert.com</a>"
        },
        {
            title: "¿Por qué no estoy recibiendo notificaciones por WhatsApp?",
            content: `<ol>
            <li><strong>Verifica tu Suscripción Activa:</strong> Accede a la sección de <em>'Información de la Cuenta'</em> para comprobar si tu suscripción está activa. Sin una suscripción activa, no se enviarán mensajes.</li>
            <li><strong>Confirma tu Número Telefónico:</strong> Dentro de <em>'Información de Cuentas'</em>, asegúrate de que el número telefónico ingresado sea correcto.</li>
            <li><strong>Opción de Recepción por WhatsApp:</strong> Comprueba si has marcado la casilla para recibir mensajes por WhatsApp en la configuración de tu cuenta. Si no está marcada, no recibirás mensajes de WhatsApp.</li>
            <li><strong>Selección de Moneda/Mercado para Notificaciones:</strong> En la sección de notificaciones, elige la moneda o el mercado del cual deseas recibir alertas.</li>
            <li><strong>Contacto con Soporte:</strong> Si después de seguir estos pasos sigues sin recibir mensajes, por favor contacta con nuestro equipo de soporte en <a href='mailto:support@dazalert.com'>support@dazalert.com</a>.</li>
          </ol>`
        },
        {
            title: "¿Cuántas veces se puede utilizar la semana de prueba gratuita?",
            content: "La semana de prueba únicamente se puede utilizar una vez. Tras su utilización, para continuar disfrutando de nuestros servicios, es necesario adquirir una suscripción, que se puede pagar con tarjeta de crédito o a través de Binance."
        },
        // Add more questions
    ];
    

    const filteredQuestions = questions.filter((question) => 
        question.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    return (
        <view>
            <section>
                <Header />
                <div className="main-content pt-7 right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div class="row justify-content-center">
                                <div class="col-xl-10">
                                    <div class="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center" >
                                        <div class="card-body p-md-5 p-4 text-center" style={{backgroundColor: 'rgba(0, 85, 255, 0.8)'}}>
                                            <h2 class="fw-700 display2-size text-white display2-md-size lh-2">Ayuda</h2>
                                            <p class="font-xsss ps-lg-5 pe-lg-5 lh-28 text-grey-200">Preguntas más comunes </p>
                                            <div class="form-group w-lg-75 mt-4 border-light border p-1 bg-white rounded-3 ms-auto me-auto">
                                                <div class="row">
                                                    <div class="col-md-8">
                                                        <div class="form-group icon-input mb-0">
                                                            <i class="ti-search font-xs text-grey-400"></i>
                                                            <input 
                                                            type="text" 
                                                            className="style1-input border-0 ps-5 font-xsss mb-0 text-grey-500 fw-500 bg-transparent" 
                                                            placeholder="Buscar preguntas.."
                                                            value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                        />
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card w-100 border-0 shadow-none bg-transparent mt-5">
                                        <div data-accordion-component="Accordion" class="accodion-style--1 accordion">
                                        {filteredQuestions.map((question, index) => (
                                            <div data-accordion-component="AccordionItem" className="card shadow-xss" key={index}>
                                            <div data-accordion-component="AccordionItemHeading" className="card-header" role="heading" aria-level="3">
                                                <div id={`accordion__heading-${index}`} aria-disabled="false" aria-expanded="false" aria-controls={`accordion__panel-${index}`} role="button" tabIndex="0" className="accordion__button" data-accordion-component="AccordionItemButton">
                                                <h5 className="fw-600 pt-2 pb-2 mb-0">{question.title}</h5>
                                                </div>
                                            </div>
                                            <div data-accordion-component="AccordionItemPanel" className="card-body" role="region" aria-labelledby={`accordion__heading-${index}`} id={`accordion__panel-${index}`} hidden="">
                                        
                                                    <p dangerouslySetInnerHTML={{ __html: question.content }}></p>
                                

                                            </div>
                                            </div>
                                        ))}
                                               
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Appfooter />
            </section>
        </view>
    )
}

export default Help;
