import React from 'react';
import { Routes, Route } from 'react-router-dom';
import About from "./pages/About";
import Cart from './pages/ShopTwo';
import Home from './pages/Home';
import Notification from './pages/Notification';
import Paquetes from './pages/Paquetes';
import AddPost from './pages/AddPost';
import DefaultSettings from './components/DefaultSettings';
import Userpage from './pages/Userpage';
import Checkout from './pages/Checkout';
import Notificationpick from './pages/Notificationpick';
import Admins from './pages/Admins';
import Suscripciones from './pages/Suscripciones';
import Help from './pages/Help';
import { useAuth0 } from "@auth0/auth0-react";
import { AuthenticationGuard } from './components/authentication-guard';
import { PageLoader } from "./components/page-loader";
import NotFoundPage from "./pages/not-found-page";
import PublicPage from "./pages/public-page"
import CallbackPage from "./pages/callback-page";
import CambioPage from './pages/Cambiopage';
import BTC from './pages/BTC';
import Grafica from './pages/Grafica';
//
const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<PublicPage />} />
      <Route
        path="/about"
        element={<AuthenticationGuard component={About} />}
      />
      <Route
        path="/noticias"
        element={<AuthenticationGuard component={Home} />}
      />
      <Route
        path="/userpage"
        element={<AuthenticationGuard component={Userpage} />}
      />
      <Route
        path="/profile"
        element={<AuthenticationGuard component={Home} />}
      />
      <Route
        path="/defaultsettings"
        element={<AuthenticationGuard component={DefaultSettings} />}
      />
      <Route
        path="/notificacion"
        element={<AuthenticationGuard component={Notification} />}
      />
      <Route
        path="/defaultnoti"
        element={<AuthenticationGuard component={Notificationpick} />}
      />
      <Route
        path="/helpbox"
        element={<AuthenticationGuard component={Help} />}
      />
      <Route
        path="/notificacionpick"
        element={<AuthenticationGuard component={Notificationpick} />}
      />
      <Route
        path="/paquetes"
        element={<AuthenticationGuard component={Paquetes} />}
      />
      <Route
        path="/cart"
        element={<AuthenticationGuard component={Cart} />}
      />
      <Route
        path="/checkout"
        element={<AuthenticationGuard component={Checkout} />}
      />
      <Route
        path="/suscription"
        element={<AuthenticationGuard component={Suscripciones} />}
      />
      <Route
        path="/admins"
        element={<AuthenticationGuard component={Admins} />}
      />
      <Route
        path="/addpost"
        element={<AuthenticationGuard component={AddPost} />}
      />
      <Route path="/public" element={<PublicPage />} />

      <Route
        path="/cambio"
        element={<AuthenticationGuard component={CambioPage} />}
      />
      <Route
        path="/btc"
        element={<AuthenticationGuard component={BTC} />}
      />
      <Route
        path="/graficaBTC"
        element={<AuthenticationGuard component={Grafica} />}
      />
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
