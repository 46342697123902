import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Appfooter from "../components/Appfooter";
import Postview from "../components/Postview";
import Load from "../components/Load";

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const Home = () => {
  const { user, logout, isAuthenticated } = useAuth0();
  const { email, picture } = user || {};
  const [, setUserData] = useState(null);
  const [numero, setNumero] = useState(0);
  const [suscripcion, setSuscripcion] = useState(0);
  const [suscripcionStudio, setSuscripcionStudio] = useState(0);
  const today = new Date();
  const vencimiento = new Date(today - 1);
  const dateold = new Date(1900, 1, 1);
  const [nickname, setNickname] = useState("");
  const [fechaCorte, setFechaCorte] = useState();
  const [counter, setCounter] = useState(0);
  const [posts, setPosts] = useState([]);

  const USERURL2 = "https://dazapis.herokuapp.com/getblogposts";
  const USERURL = "https://dazapis.herokuapp.com/userdata";
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post(USERURL, {
          email: user.email,
          numero: 0,
          suscripcion: 0,
          suscripcionStudio: 0,
          fechacorte: vencimiento.toISOString(),
          fechaCorteStudio: vencimiento.toISOString(),
          prueba: 0,
          country: 502,
          country2: "gt",
          consentdate: dateold,
          whatsapp: false,
          nickname: ""
        });
        console.log(response.data)
        setUserData(response.data);
        setNickname(response.data.nickname); // Assuming the response contains a 'nickname' field
        setSuscripcion(response.data.suscription);
        setSuscripcionStudio(response.data.suscripcionStudio);
        setCounter(1);
      } catch (error) {
        console.error(error);
      }
    };

    if (isAuthenticated && user) {
      fetchUserData();
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(USERURL2);
        const fetchedPosts = response.data;

        const filteredPosts = fetchedPosts.filter((post) => {
          if (suscripcion !== 0 && suscripcionStudio !== 0 ){
            return post.type === "General" || post.type === "Estudio" || post.type === "DazAlert";
          } else if (suscripcionStudio !== 0) {
            return post.type === "General" || post.type === "Estudio";
          } else if (suscripcion !== 0) {
            return post.type === "DazAlert" || post.type === "General";
          } else {
            return post.type === "General";
          }
        });
        console.log("Filtered Posts:", filteredPosts);
        setPosts(filteredPosts);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    if (counter === 1 ) {
      fetchPosts();
    }
  }, [suscripcionStudio, suscripcion, counter]);

  const handlePostRemoved = (postId) => {
    setPosts((prevPosts) => prevPosts.filter((post) => post.postid !== postId));
  };


  return (
    <section>
      <Header />
      <div className="flexGrow">
        <button onClick={logout}>Sign Out</button>
      </div>
      <div className="main-content pt-7 right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row feed-body">
              {posts.map((post, index) => (
                <Postview
                  key={index}
                  onPostRemoved={handlePostRemoved}
                  id={post.postid}
                  postimage={post.image_url}
                  avater={post.user_picture}
                  users={post.autor}
                  time={post.fecha_post}
                  des={post.contenido}
                  userEmail={email}
                  picture={picture}
                  autor={post.email_autor}
                  userNickname={nickname} // Pass the nickname here
                  nickname={post.nickname}
                />
              ))}
              <Load />
            </div>
          </div>
        </div>
      </div>
      <Appfooter />
    </section>
  );
};

export default Home;
