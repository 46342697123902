
import Header from "./Header";
import Appfooter from "./Appfooter";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";


import { useAuth0 } from "@auth0/auth0-react";
const DefaultSettings = () => {

    const auth0 = useAuth0();
    const {user} = auth0;
    const { email} = user;
    const [isAdmin, setIsAdmin] = useState(false);
    const USERURL = "https://dazapis.herokuapp.com/checkadmin"

  useEffect(() => {

    axios.post(`${USERURL}`, {email: email})
    .then(response => {
        if(response.data && response.data.isAdmin) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
    })
    .catch(error => {
        console.error("Error checking admin status:", error);
    });
}, [user]);



    const {logout} = useAuth0();

    

    const handleLogout = () => {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      };

      const bgMiniGradientStyle1 = {
        background: 'linear-gradient(to right,#1e6091, #184e77)',
        /* Add more gradient stops or customize as needed */
      };
    
      const bgMiniGradientStyle2 = {
        background: 'linear-gradient(to right,#0f80aa, #0353a4)',
        /* Add more gradient stops or customize as needed */
      };

      const bgMiniGradientStyle3 = {
        background: 'linear-gradient(to right,#168aad, #1a759f)',
        /* Add more gradient stops or customize as needed */
      };
      const bgMiniGradientStyle4 = {
        background: 'linear-gradient(to right,#52b69a, #34a0a4)',
        /* Add more gradient stops or customize as needed */
      };
    
      const bgMiniGradientStyle5 = {
        background: 'linear-gradient(to right, #52b69a, #76c893)',
        /* Add more gradient stops or customize as needed */
      };
      
      const bgMiniGradientStyle6 = {
        background: 'linear-gradient(to right,#99d98c, #76c893)',
        /* Add more gradient stops or customize as needed */
      };
    
      const bgMiniGradientStyle7 = {
        background: 'linear-gradient(to right, #92C785, #a4d973)',
        /* Add more gradient stops or customize as needed */
    };
    const bgMiniGradientStyle8 = {
      background: 'linear-gradient(to right, #a4d973, #bfe456)',
      /* Add more gradient stops or customize as needed */
    };
    
    const bgMiniGradientStyle9 = {
      background: 'linear-gradient(to right, #bfe456, #D0DB50)', // Different color values while maintaining the same gradient style
      /* Add more gradient stops or customize as needed */
    };
    
    const bgMiniGradientStyle10 = {
      background: 'linear-gradient(to right, #D0DB50, #E5FF6F)', // Different color values while maintaining the same gradient style
      /* Add more gradient stops or customize as needed */
    };
      
    return (
        <section>
        <Header />

            <div className="main-content pt-7 right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div class="middle-sidebar-bottom">
                            <div class="middle-sidebar-left">
                                <div class="middle-wrap">
                                    <div class="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                        <div class="card-body p-lg-5 p-4 w-100 border-0">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                     <h4 class="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs">Ajustes</h4>
                                                     <div class="nav-caption fw-600 font-xsss text-grey-500 mb-2">General</div>
                                                     <ul class="list-inline mb-4">
                                                        <li class="list-inline-item d-block border-bottom me-0">
                                                        <Link
                                                            activeclassname="active"
                                                            to={"/userpage"}
                                                            className="pt-2 pb-2 d-flex align-items-center"
                                                            >
                                    
                                                            <i class="btn-round-md bg-primary-gradiant text-white feather-user font-md me-3"></i> 
                                                            <h4 class="fw-600 font-xsss mb-0 mt-0">Información de la cuenta</h4>
                                                            <i class="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                                                            </Link>
                                                        </li>
                                                    
                                                    </ul>
                                                    {isAdmin  && (
                                                        <div>
                                                        <div class="nav-caption fw-600 font-xsss text-grey-500 mb-2">Administrador</div>
                                                        
                                                        <ul class="list-inline mb-4">
                                                        <li class="list-inline-item d-block border-bottom me-0">
                                                            <Link to="/addpost" 
                                                            className="pt-2 pb-2 d-flex align-items-center">
                                                            <i className="feather-plus-circle btn-round-md  text-white font-md me-3" style={bgMiniGradientStyle6}></i>
                                                            <h4 class="fw-600 font-xsss mb-0 mt-0">Agregar post</h4>
                                                            <i class="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                                                            </Link>
                                                        </li>


                                                        <li class="list-inline-item d-block border-bottom me-0">
                                                            <Link to="/suscription" 
                                                            className="pt-2 pb-2 d-flex align-items-center">
                                                            <i className="feather-layers btn-round-md  text-white font-md me-3" style={bgMiniGradientStyle7}></i>
                                                            <h4 class="fw-600 font-xsss mb-0 mt-0">Suscripciones</h4>
                                                            <i class="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                                                            </Link>
                                                        </li>
                                                   
                                                        <li class="list-inline-item d-block border-bottom me-0">
                                                            <Link to="/admins" className="pt-2 pb-2 d-flex align-items-center">
                                                            <i className="feather-user btn-round-md  text-white font-md me-3" style={bgMiniGradientStyle8}></i>
                                                            <h4 class="fw-600 font-xsss mb-0 mt-0">Nuevos administradores</h4>
                                                            <i class="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                                                            </Link>
                                                        </li>
                                                       
                                                        <li class="list-inline-item d-block border-bottom me-0">
                                                            <Link to="/cambio" className="pt-2 pb-2 d-flex align-items-center">
                                                            <i className="feather-dollar-sign btn-round-md  text-white font-md me-3" style={bgMiniGradientStyle9}></i>
                                                            <h4 class="fw-600 font-xsss mb-0 mt-0">Cambios en pago</h4>
                                                            <i class="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                                                            </Link>
                                                        </li>
                                                        <li class="list-inline-item d-block border-bottom me-0">
                                                            <Link to="/btc" className="pt-2 pb-2 d-flex align-items-center">
                                                            <i className="feather-database btn-round-md  text-white font-md me-3" style={bgMiniGradientStyle10}></i>
                                                            <h4 class="fw-600 font-xsss mb-0 mt-0">Cambios en datos BTC</h4>
                                                            <i class="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                                                            </Link>
                                                        </li>
                                                        </ul>
                                                        </div>
                                                        )}
                                                    
                                                    <div class="nav-caption fw-600 font-xsss text-grey-500 mb-2">Otros</div>
                                                    <ul class="list-inline">
                                                        <li class="list-inline-item d-block border-bottom me-0">
                                                        <Link
                                                            activeclassname="active"
                                                            to="/defaultnoti"
                                                            className="pt-2 pb-2 d-flex align-items-center"
                                                            >
                                                           
                                                                <i class="btn-round-md text-white feather-send  bg-gold-gradiant btn-round-md  me-3"></i>
                                                                 <h4 class="fw-600 font-xsss mb-0 mt-0">Notificaciones</h4>
                                                                 <i class="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                                                            
                                                            </Link>
                                                        </li>
                                                        <li class="list-inline-item d-block border-bottom me-0">
                                                        <Link
                                                            activeclassname="active"
                                                            to="/noticias"
                                                            className="pt-2 pb-2 d-flex align-items-center"
                                                            >
                                                           
                                                                <i class="btn-round-md  text-white feather-tv font-md me-3" style={bgMiniGradientStyle2}></i>
                                                                 <h4 class="fw-600 font-xsss mb-0 mt-0">Noticias</h4>
                                                                 <i class="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                                                            
                                                            </Link>
                                                        </li>
                                                        <li class="list-inline-item d-block border-bottom me-0">
                                                        <Link
                                                            activeclassname="active"
                                                            to="/graficaBTC"
                                                            className="pt-2 pb-2 d-flex align-items-center"
                                                            >
                                                           
                                                                <i class="btn-round-md text-white feather-bar-chart-2 font-md me-3" style={bgMiniGradientStyle5}></i>
                                                                 <h4 class="fw-600 font-xsss mb-0 mt-0">BTC</h4>
                                                                 <i class="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                                                            
                                                            </Link>
                                                        </li>
                                                        <li class="list-inline-item d-block border-bottom me-0">
                                                        <Link
                                                            activeclassname="active"
                                                            to="/helpbox"
                                                            className="pt-2 pb-2 d-flex align-items-center"
                                                            >
                                                                <i class="btn-round-md bg-primary-gradiant text-white feather-help-circle font-md me-3"></i> 
                                                                <h4 class="fw-600 font-xsss mb-0 mt-0">Ayuda</h4>
                                                                <i class="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                                                            </Link>
                                                        </li>
                                                        
                                                        <li class="list-inline-item d-block me-0">
                                                            <div onClick={handleLogout} class="pt-2 pb-2 d-flex align-items-center">
                                                                <i class="btn-round-md bg-red-gradiant text-white feather-lock font-md me-3"></i> 
                                                                <h4 class="fw-600 font-xsss mb-0 mt-0">Cerrar Sesión</h4>
                                                                <i class="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        </div>
                                        </div>

                    </div>
                </div>
            </div>
            <Appfooter />
        </section>
     
    )
}

export default DefaultSettings
