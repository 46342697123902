import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Appfooter from "../components/Appfooter";
import { Fragment, useRef } from "react";
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";


const Checkout = (props) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [codeInputVisible, setCodeInputVisible] = useState(false);
  const [captureInputVisible, setCaptureInputVisible] = useState(false);
  const [codigo2, setCodigo2] = useState('');
  const [codigo, setCodigo] = useState('');
  const [image, setImage] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isPrints, setIsPrints] = useState(true);
  const [showSuccessButton, setShowSuccessButton] = useState(false);
  const [showSuccessButton2, setShowSuccessButton2] = useState(false);
  const USERURL = "https://dazapis.herokuapp.com/updateuser"
  const comentsURL = "https://dazapis.herokuapp.com/addrecord"
  const comentsURL2 = "https://dazapis.herokuapp.com/addrecord2"
  const inputCodigoRef = useRef(null);

  const sessionDataPrice = sessionStorage.getItem("price");
  const sessionDataSuscription = sessionStorage.getItem("subscription");

  const { user } = useAuth0();
  const { email } = user;
  const getCodigo = "https://dazapis.herokuapp.com/getcode";
  const getQR = "https://dazapis.herokuapp.com/getqr";
  const [qrImage, setQrImage] = useState(null);

  useEffect(() => {
    checkButtonEnabled();
    checkPrints();
  }, [codigo, image, selectedPaymentMethod]);

  useEffect(() => {
    // Function to fetch the codigo from the SQL table
    const fetchCodigo = async () => {
      try {
        // Replace this with your actual database fetching logic
        const response = await axios.get(getCodigo);
        setCodigo2(response.data.codigo);
        //console.log(response.data.codigo) // Set the fetched codigo into the state
      } catch (error) {
        console.error('Error fetching codigo:', error);
      }
    };

    fetchCodigo();
  }, []); // The empty array ensures this effect runs only once after the initial render

  useEffect(() => {
    const fetchQrImage = async () => {
        try {
            const response = await axios.get(getQR);

            // Check if the response contains the QR image data
            if (response.data.qr_image) {
                setQrImage(response.data.qr_image);
            } else {
                //console.log('No QR image found');
            }
        } catch (error) {
            console.error('Error fetching QR image:', error);
            // Handle the error appropriately
        }
    };

    fetchQrImage();
}, []);

  const handlePaymentMethodChange = (event) => {
    const method = event.target.value;
    setSelectedPaymentMethod(method);
    setCodeInputVisible(method === "codigo");
    setCaptureInputVisible(method === "captura");
  };

  const handleFileUpload = (event) => {
    setImage(event.target.files[0]);
  };

  const handleCodigoChange = (event) => {
    setCodigo(event.target.value);
  };

  const checkButtonEnabled = () => {
    const subscription = sessionDataSuscription


    let isEnabled = (codigo || image) && selectedPaymentMethod !== 'link';
    if (subscription === "Prueba Gratuita de 7 días") {
      isEnabled = true;
    }

    setIsButtonEnabled(isEnabled);
  };

  const checkPrints = () => {
    const subscription = sessionDataSuscription
    let prints = true;
    if (subscription === "Prueba Gratuita de 7 días") {
      prints = false;
    }
    setIsPrints(prints)
  }

  const handleConfirmacionClick = () => {
    const subscription = sessionDataSuscription
    const price = sessionDataPrice

      // If subscription is "Prueba Gratuita de 7 días" and price is 0
    if (subscription === "Prueba Gratuita de 7 días") {
          // Make the Axios request to update user record
          axios.post(USERURL, {
            email: email, // Using the Auth0 email
            prueba: 1,
            fechacorte: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), // One week from now
            suscription: 23
          }).then((response) => {
            //console.log('User updated successfully:', response.data);
            // Set the state to show the button
            setShowSuccessButton(true);
          }).catch((error) => {
            console.error('User update error:', error);
          });
          return; // Exit the function
    } 

    if (selectedPaymentMethod === 'codigo') {
        // Send the "Txid" code to the server for verification
        //console.log("AQUI")
        axios.post(comentsURL, { codigotix: codigo, email: email, suscription:subscription, precio:price })
            .then(response => {
                // Handle success. For example, updating the user or showing a message.
                // Here, I'm assuming that you'll use a state variable named "showSuccessButton2" to control the display of the success message.
                setShowSuccessButton2(true);
                setIsPrints(false)
                // Clear the value using ref
                if (inputCodigoRef.current) {
                  inputCodigoRef.current.value = '';
              }
            })
            .catch(error => {
                // Handle error. Inform the user of the error.
                console.error("Error while verifying the codigo:", error);
                // TODO: Add any user-facing error handling here, such as displaying an error message to the user.
            });
    } else if (selectedPaymentMethod === 'captura') {
        // Send the screenshot to the server for verification
        //console.log("AQUI CAPTURA")
        const formData = new FormData();
        formData.append('image', image);
        formData.append('suscription', subscription)
        formData.append('precio', price)
        formData.append('email', email);

        axios.post(comentsURL2, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(response => {
            // Handle success. Again, using the "showSuccessButton2" as an example.
            setShowSuccessButton2(true);
            setIsPrints(false)
        })
        .catch(error => {
            // Handle error. Inform the user of the error.
            console.error("Error while verifying the captura:", error);
            // TODO: Add any user-facing error handling here.
        });
    } else {
        // Handle other payment methods or show an error for unsupported payment methods
        console.error("Unsupported payment method selected:", selectedPaymentMethod);
        // TODO: Maybe inform the user that they've selected an unsupported payment method.
    }
};

    return (
      <Fragment>
        <Header />
        <div className="main-content pt-7  right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
              <div class="row">
                <div class="col-lg-12 mb-3">
                  <div class="card p-md-5 p-4 bg-primary-gradiant rounded-3 shadow-xss bg-pattern border-0 overflow-hidden">
                    <div class="bg-pattern-div"></div>
                    <h2 class="display2-size display2-md-size fw-700 text-white mb-0 mt-0">Finalizar compra</h2>
                  </div>
                </div>
              </div>
              <div class="row">
                
                <div class="col-xl-6 col-lg-6">
                  <div class="page-title">
                    {showSuccessButton && (
                      <div>
                        <p class="mont-font fw-600 font-md mb-lg-5 mb-4">Tu compra ha sido exitosa!</p>
                        <button class=" bg-warning float-left text-white fw-600 text-uppercase font-xssss border-dark border rounded-3 border-size-md d-inline-block w500 p-3 text-center ls-3" onClick={() => window.location.href = '/'}>
                          Regresar a la página principal 
                        </button>
                        {/* Alternatively, you can use the Link component */}
                        {/* <Link to="/home">Go to Home</Link> */}
                      </div>
                    )}
                    {showSuccessButton2 && (
                      <div>
                        <p class="mont-font fw-600 font-md mb-lg-5 mb-4">Tu compra esta siendo verificada por nuestro equipo. En 24 horas estará tu suscripción activada!</p>
                        <button class=" bg-warning float-left text-white fw-600 text-uppercase font-xssss border-dark border rounded-3 border-size-md d-inline-block w500 p-3 text-center ls-3" onClick={() => window.location.href = '/'}>
                          Regresar a la página principal 
                        </button>
                        {/* Alternatively, you can use the Link component */}
                        {/* <Link to="/home">Go to Home</Link> */}
                      </div>
                    )}
                    {isPrints && (

                    <div>
                    <h4 class="mont-font fw-600 font-md mb-lg-5 mb-4">¿Cómo pagar?</h4>
                    
                

             
                    <label >Enviar el monto a pagar en USDT a la direccion de la wallet,  Para envíar USDT puede escanear el código QR o ingresar la direccion de la wallet proporcionados en la siguiente sección. Asegurarse que el pago sea en USDT / Trc20 </label>
                    <p></p>
                    <label >Al haber pagado ingresar el código de verificación txid dado por la aplicación o subir la captura de pantalla del comprobante. Esa verificación será revisada por nuestro equipo y se dará inicio a la suscripción.</label>
                    <p></p>
                    <label class="mont-font fw-600 font-xssss text-red">* Verifique enviar solo USDT (Trc20) a esta dirección. El envío de cualquier otra moneda puede resultar en una pérdida permanente de sus fondos.</label>
                    <div class="form-gorup">
                            
                            
                          </div>
                
                          <h4 class="mont-font fw-600 font-md mt-3 ">Código Txid</h4>
                          <label style={{
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',  // Optional: Add ellipsis for long text
                            whiteSpace: 'normal',
                            fontSize : '0.9rem',
                            /* Add any additional styles based on your design */
                        }} >{codigo2}</label>
                          <h4 class="mont-font fw-600 font-md mt-4">Código QR</h4>
                    <img src={`data:image/png;base64, ${qrImage}`}
                                            alt="QR Code"
                                            style={{ width: '300px', height: '300px', marginBottom: '20px' }} 
                                            class="w-100"/>
                    </div>
                    )}
                  </div>
                  </div>
                  
                  <div class="col-xl-5 offset-xl-1 col-lg-6">
                  <div class="order-details">
                  <h4 class="mont-font fw-600 font-md mb-5">Detalles de la compra</h4>
                    <div class="table-content table-responsive mb-5 card border-0 bg-greyblue p-lg-5 p-4">
                      <table class="table order-table order-table-2 mb-0">
                        <thead>
                          <tr>
                            <th class="border-0">Producto</th>
                            <th class="text-right border-0">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th class="text-grey-500 fw-500 font-xsss">Suscripción: <strong><span>{sessionDataSuscription}</span></strong></th>
                            <td class="text-right text-grey-500 fw-500 font-xsss">${sessionDataPrice}</td>
                          </tr>
                         
                        </tbody>
                        <tfoot>
                         
                          <tr class="order-total">
                            <th>Total de la orden</th>
                            <td class="text-right text-grey-700 font-xsss fw-700"><span class="order-total-ammount">${sessionDataPrice}</span></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    {isPrints && (
                    <div class="checkout-payment card border-0 mb-3 bg-greyblue p-lg-5 p-4">
                    <form action="#" className="payment-form">
          <div className="payment-group mb-4">
            <div className="payment-radio">
              <input
                type="radio"
                name="pago"
                id="codigo"
                value="codigo"
                checked={selectedPaymentMethod === "codigo"}
                onChange={handlePaymentMethodChange}
              />
              <label className="payment-label fw-600 font-xsss text-grey-900 ms-2">
                Código "Txid"
              </label>
            </div>
            {codeInputVisible && (
            <div className="payment-info" data-method="codigo">
              <p className="font-xssss lh-24 text-grey-500 ps-4">
                Por favor ingresar el código Txid proporcionado por su
                aplicación de Binance para confirmar la transacción.
              </p>
              <input
                type="text"
                ref={inputCodigoRef}
                id="input-codigo"
                className="lh-24 font-xssss text-black w-100"
                value={codigo}
                onChange={handleCodigoChange}
              />
            </div>
            )}
          </div>

          <div className="payment-group mb-4">
            <div className="payment-radio">
              <input type="radio"
               name="pago"
                id="captura" 
                value="captura"
                checked={selectedPaymentMethod === "captura"}
                onChange={handlePaymentMethodChange}
                 />
              <label className="payment-label fw-600 font-xsss text-grey-900 ms-2">
                Captura de pantalla
              </label>
            </div>
            {captureInputVisible && (
            <div className="payment-info cheque hide-in-default" data-method="captura">
              <p className="font-xssss lh-24 text-grey-500 ps-4">
                Por favor suba la captura de pantalla de su aplicación de Binance
                para confirmar la transacción.
              </p>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileUpload}
              />
            </div>
             )}
          </div>

          {/*
          <div className="payment-group mb-4">
                        <div className="payment-radio">
                          <input
                            type="radio"
                            name="pago"
                            id="link"
                            value="link"
                            checked={selectedPaymentMethod === "link"}
                            onChange={handlePaymentMethodChange}
                          />
                          <label className="payment-label fw-600 font-xsss text-grey-900 ms-2">
                            Pago con tarjeta
                          </label>
                        </div>
                        {selectedPaymentMethod === "link" && (
                          <div className="payment-info" data-method="link">
                            <p className="font-xssss lh-24 text-grey-500 ps-4">
                              Haz click en el botón abajo para pagar mediante un enlace seguro.
                            </p>
                            <a href="https://payments.qpaypro.com/checkout/7fRlOWciYsHl3675/208973" target="_blank"  rel="noopener noreferrer" > Pagar Ahora </a>
                          </div>
                        )}
                      </div>
                        */}
        </form>
                          </div>
                    )}

                          <div class="clearfix"></div>
                          <div class="card shadow-none border-0">
                            <button href="/checkout" 
                              onClick={handleConfirmacionClick}
                              className={`w-100 p-3 mt-3 font-xsss text-center text-white bg-current rounded-3 text-uppercase fw-600 ls-3 ${
                                !isButtonEnabled ? 'disabled' : ''
                              }`}
                              disabled={!isButtonEnabled}
                          >Subir confirmación</button>
                          </div>
                          </div>
                          </div>
                          </div>
                        </div>

                    
            
          </div>
        </div>
        <Appfooter />
      </Fragment>
    );
}

export default Checkout;
