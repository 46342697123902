import Header from "../components/Header";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import axios from "axios";
import Load from "../components/Load";
import { Link} from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Userpage = () => {
    
   
    const {user} = useAuth0();
    const { name, picture, email } = user;
    const [numero, setNumero] = useState(0)
    const [suscripcion, setSuscripcion] = useState(0)
    const USERURL = "https://dazapis.herokuapp.com/userdata"//http://127.0.0.1:5000/userdata"  //"https://dazapis.herokuapp.com/userdata" 
    const USERURL2 = "https://dazapis.herokuapp.com/userdata2" //"http://127.0.0.1:5000/userdata2" //"https://dazapis.herokuapp.com/userdata2" //"http://127.0.0.1:5000/userdata2"//"https://dazapis.herokuapp.com/userdata"
    const GET_STUDIO_STATUS_URL = 'https://dazapis.herokuapp.com/getStudioStatus';
    const [loading, setLoading] = useState(true)
    const today = new Date()
    const vencimiento = new Date(today-1)
    const dateold = new Date(1900,1,1)
    const [fechaCorte, setFechaCorte] = useState(vencimiento)
    const [fechaCorteStudio, setFechaCorteStudio] = useState(vencimiento)
    const [newNum, setNewNum] = useState(undefined);
    const [reren, setReren] = useState(0)
    const [trial, setTrial] = useState(0)
    const [countrycode, setCountryCode] = useState(502)
    const [countrycode2, setCountryCode2] = useState("gt")
    const [whatsAppConsent, setWhatsAppConsent] = useState(false);
    const [initialWhatsAppConsent, setInitialWhatsAppConsent] = useState(false);
    const [suscripcionStudio, setSuscripcionStudio] = useState(0);
    const [nickname, setNickname] = useState("");
    const [initialNickname, setInitialNickname] = useState("");
    const [studioEnabled, setStudioEnabled] = useState(0);
    let frase ;
    const [consent, setConsent] = useState(dateold);
    const handleCheckboxChange = (event) => {
        setWhatsAppConsent(event.target.checked);
    };

    const isNumeric = value => {
        return /^\d+$/.test(value);
      };
  
      const phoneNumberChanged = numero !== newNum && newNum !== undefined && newNum !== 0 && isNumeric(newNum);
      const whatsAppConsentChanged = whatsAppConsent !== initialWhatsAppConsent;
      const nicknameChanged = nickname !== initialNickname;
      
    

    useEffect(() => {
        userdata()
        studioStat()
    }, [reren])

    const studioStat = async (e) => {
        axios.get(GET_STUDIO_STATUS_URL)
         .then(response => {
             setStudioEnabled(response.data.enabled);
         })
         .catch(error => {
             console.error("Error fetching studio status:", error);
         });
    
     } 
        
    const userdata = async (e) => {
       //e.preventDefault();
        //console.log("CONSENT WHATS: " +whatsAppConsent)
         await axios.post(USERURL,
            {
                        "email": email,
                        "numero" : numero,
                        "suscripcion" : suscripcion,
                        "fechacorte" : vencimiento,
                        "prueba": trial,
                        "country": countrycode,
                        "country2":countrycode2,
                        "consentdate": consent,
                        "whatsapp": whatsAppConsent,
                        "nickname": nickname,
                        "suscripcionStudio": suscripcionStudio,
                        "fechaCorteStudio": fechaCorteStudio


            },{
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
        }).then(response => {
            //console.log(response)
            setNumero(response?.data?.numero)
            setSuscripcion(response?.data?.suscription)
            setSuscripcionStudio(response?.data?.suscripcionStudio)
            setFechaCorte(new Date(response?.data?.fechacorte))
            setFechaCorteStudio(new Date(response?.data?.fechaCorteStudio))
            setTrial(response?.data?.prueba)
            setCountryCode(response?.data?.country)
            setCountryCode2(response?.data?.country2)
            setWhatsAppConsent(response?.data?.whatsapp);
            setNickname(response?.data?.nickname);
            setInitialNickname(response?.data?.nickname);
            setInitialWhatsAppConsent(response?.data?.whatsapp);
            setConsent(response?.data?.consentdate)
            setLoading(false)
            
            //history.push('/');
            
            })
            .catch(error => {
              //alert(error.response.data);
            });

        

    } 

    const handleSave = async (e) => {
        setLoading(true)
        let consent1;
        if (whatsAppConsent === false) {
            consent1 = dateold
        }else {
            consent1 = today

            
        }
        let numberWithoutCode;
        if (numero !== newNum && newNum !== undefined && newNum !== 0 && isNumeric(newNum)){
            numberWithoutCode = newNum.slice(countrycode.length);
        }
        else{
            numberWithoutCode = numero
        }
        //console.log("CONSENT" + consent1)
        //console.log("number"+ numberWithoutCode)
         await axios.post(USERURL2,
                {
                            "email": email,
                            "numero" : numberWithoutCode,
                            "suscripcion" : suscripcion,
                            "suscripcionStudio": suscripcionStudio,
                            "fechacorte" : vencimiento,
                            "fechaCorteStudio" : fechaCorteStudio,
                            "country": countrycode,
                            "country2" : countrycode2,
                            "consentdate": consent1,
                            "whatsapp": whatsAppConsent,
                            "nickname": nickname
                },{
                            headers: { 'Content-Type': 'application/json' },
                            withCredentials: true
            }).then(response => {
                //console.log(response)
                setNumero(response?.data?.numero)
                setCountryCode(response?.data?.country)
                setCountryCode2(response?.data?.country2)
                setWhatsAppConsent(response?.data?.whatsapp)
                setNickname(response?.data?.nickname);
                setInitialNickname(response?.data?.nickname);
               // console.log("VEAMOS: " + response?.data?.whatsapp)
                setNewNum(0)
                setReren(reren+1)
                setLoading(false)
                
                
                
            })
            .catch(error => {
                console.error("Error:", error);
                setLoading(false);
          
             });

        
    }
   
    //console.log('Vencimiento:', vencimiento);
    //console.log('Today:', today);
    //console.log('CORTE:', fechaCorte);


    if (suscripcion === 0){
        frase = "No tienes una suscripción activa. "
    } else if (suscripcion === 465 ){
        frase = "1 mes ilimitado. "
    } else if (suscripcion === 884 ){
        frase = "1 año ilimitado. "
    }else if (suscripcion === 374 ){
        frase = "1 mes limitado. "
    } else if (suscripcion === 985 ){
        frase = "1 año limitado. "
    }else if (suscripcion === 23 ){
        frase = "Prueba gratuita de 7 días. "
    }
        
    const formattedDate = fechaCorte.toLocaleDateString('es-ES', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    const formattedDateStu = fechaCorteStudio.toLocaleDateString('es-ES', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
    

    if (loading) {
        // Show loading screen while loading is true
        return <Load/>;
      }
    

    return (
        
        <section>
            <Header></Header>
            <div className="main-content pt-7 right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div class="middle-sidebar-left">
                        <div class="middle-wrap">
                            <div class="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div class="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                    <Link 
                                    to={"/defaultsettings"}
                                    class="d-inline-block mt-2" >
                                        <i class="ti-arrow-left font-sm text-white"></i>
                                    </Link>
                                    <h4 class="font-xs text-white fw-600 ms-4 mb-0 mt-2">Detalles de la cuenta</h4>
                                </div>
                                <div class="card-body p-lg-5 p-4 w-100 border-0 ">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-4 text-center">
                                            <figure class="avatar ms-auto me-auto mb-0 mt-2 w100">
                                                <img src={picture} alt="avatar" referrerPolicy="no-referrer" class="shadow-sm rounded-3 w-100"/>
                                            </figure>
                                            <h2 class="fw-700 font-sm text-grey-900 mt-3">{name}</h2>
                                        </div>
                                    </div>
                                    <form action="#">
                                        <div class="row">
                                            <div class="col-lg-6 mb-3">
                                                <div class="form-group">
                                                    <label class="mont-font fw-600 font-xsss mb-2">Correo Electrónico</label>
                                                    <p type="text"  class="form-control">{email}</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 mb-3">
                                            <div class="form-group">
                                                <label class="mont-font fw-600 font-xsss mb-2">Apodo</label>
                                                <input 
                                                    type="text" 
                                                    class="form-control" 
                                                    value={nickname} 
                                                    onChange={(e) => setNickname(e.target.value)}
                                                    placeholder={nickname ? nickname : 'Introduce tu apodo'}
                                                />
                                            </div>
                                        </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6 mb-3">
                                                <div class="form-group">
                                                    <label class="mont-font fw-600 font-xsss mb-2">Número de teléfono</label>
                                                    {numero === 0 &&
                                                    <PhoneInput
                                                    country={'gt'}
                                                    class="form-control"
                                                    value={newNum}
                                                    enableSearch = {true}
                                                    onChange={(value, country) => {
                                                        setNewNum(value);
                                                        setCountryCode2(country.countryCode);
                                                        setCountryCode(country.dialCode);
                                                      }}
                                                    />
                                                    
                                                    
                                                    }
                                                
                                                    
                                                    {numero !== 0 &&
                                                    <PhoneInput
                                                    //country={'us'}
                                                    class="form-control"
                                                    value={newNum} 
                                                    enableSearch = {true}
                                                    placeholder={numero !== 0 ? `(+${countrycode}) ${numero} ` : ''}
                                                    
                                                    onChange={(value, country) => {
                                                        setNewNum(value);
                                                        setCountryCode2(country.countryCode);
                                                        setCountryCode(country.dialCode);
                                                      }}
                                                    />
                                                    }
                                                    
                                                </div>
                                            </div>
                                            <div class="col-lg-6 mb-3">
                                            <div class="form-group">
                                                <label class="mont-font fw-600 font-xsss mb-2 p-1 ">Notificaciones por WhatsApp</label>
                                                <input  class= "p-4" type="checkbox" id="whatsapp" name="whatsapp" value="agree" required checked = {whatsAppConsent} onChange={handleCheckboxChange} />
                                                <label for="whatsapp"> Acepto recibir notificaciones y actualizaciones de WhatsApp.</label>
                                               
                                            </div>
                                        </div>
                                        </div>

                                         <div class="row">
                                            <div class="col-lg-12 mb-3">
                                                <div class="form-group">
                                                    <label class="mont-font fw-600 font-xsss mb-2">Suscripción actual DazAlert</label>
                                                    
                                                    {fechaCorte < today && trial === 0  &&
                                                    //no tiene suscripcion activa
                                                    
                                                    <div class="d-flex align-items-center">
                                    
                                                    <Link to="/paquetes" class="bg-current form-control text-center text-white font-xsss fw-500 p-1 mb-3 w500 rounded-3 d-inline-block align-self-center">
                                                            <p>Obtener prueba gratis de 7 días</p>
                                                        </Link>
                                                    </div>
                                                    }
                                                    {fechaCorte < today && trial !== 0  &&
                                                    //no tiene suscripcion activa
                                                    
                                                    <div class="d-flex align-items-center">
                                                    <Link to="/paquetes" class="bg-current form-control text-center text-white font-xsss fw-500 p-1 mb-3 w500 rounded-3 d-inline-block align-self-center">
                                                            <p>No cuentas con una suscripción activa. Suscribirse</p>
                                                        </Link>
                                                    </div>
                                                    }
                                                    {fechaCorte >= today &&
                                                    //si tiene suscripcion activa
                                                        <p type="text" class="form-control">{frase} Tu suscripción vence el { formattedDate} </p>
                                                    }
                                                    
                                                    
                                                 
                                                </div>
                                            </div>
                                            {studioEnabled && (        
                                            <div class="col-lg-12 mb-3">
                                                <div class="form-group">
                                                    <label class="mont-font fw-600 font-xsss mb-2">Suscripción actual DazAlert Estudio</label>
                                                    
                                                    {fechaCorteStudio < today  &&
                                                    //no tiene suscripcion activa
                                                    
                                                    <div class="d-flex align-items-center">
                                                    <Link to="/paquetes" class="bg-current form-control text-center text-white font-xsss fw-500 p-1 mb-3 w500 rounded-3 d-inline-block align-self-center">
                                                            <p>No cuentas con una suscripción activa. Suscribirse</p>
                                                        </Link>
                                                    </div>
                                                    }
                                                    {fechaCorteStudio >= today &&
                                                    //si tiene suscripcion activa
                                                        <p type="text" class="form-control"> Tu suscripción vence cuando se cierre el Estudio </p>
                                                    }
                                                    
                                                    
                                                 
                                                </div>
                                            </div>
                                            )}

                                            <div class="col-lg-12 d-flex justify-content-center align-items-center">
                                                <button onClick={handleSave} class={`bg-current text-center text-white font-xsss fw-600 p-1 w175 rounded-3 d-inline-block  ${phoneNumberChanged || whatsAppConsentChanged  || nicknameChanged ? '' : 'disabled'}`}>
                                                    <p>Guardar Datos</p>
                                                </button>
                                         
                                              
                                               
                                                
                                            </div>
                                        </div>
                                                
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Userpage;