import Header from "../components/Header";
import Appfooter from "../components/Appfooter";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
import axios from 'axios';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import ReactQuill from 'react-quill';

const AddPost = () => {
    const { user } = useAuth0();
    const { name, picture, email } = user;
    const [postContent, setPostContent] = useState("");
    const [nickname,setNickname] = useState('');
    const [postType, setPostType] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageName, setSelectedImageName] = useState('');
    const USERURL = "https://dazapis.herokuapp.com/postblog"
    const [isAdmin, setIsAdmin] = useState(false);
    const [isModerador, setIsModerador] = useState(false)
    const [isAdminStu, setIsAdminStu] = useState(false);
    const [isModeradorStu, setIsModeradorStu] = useState(false)
    const USERURL2 = "https://dazapis.herokuapp.com/checkadmin"
    const MODERADORURL = "https://dazapis.herokuapp.com/checkmoderador";
    const USERURLSTU = "https://dazapis.herokuapp.com/checkadminStu"
    const MODERADORURLSTU = "https://dazapis.herokuapp.com/checkmoderadorStu";

    const handleImageChange = (e) => {
        setSelectedImage(e.target.files[0]);
        setSelectedImageName(e.target.files[0]?.name);
    };

    const handlePostSubmit = () => {
        //console.log("CLICKED")
        const formData = new FormData();
        formData.append('content', postContent);
        formData.append('name', name);       // append the user's name
        formData.append('email', email);     // append the user's email
        formData.append('picture', picture); 
        formData.append('type', postType);    // append the user's email
        if (selectedImage) {
            formData.append('image', selectedImage);
        }
        //console.log("CLICKED 2")
        axios.post(USERURL, formData)
            .then(response => {
                setPostContent("");
                setSelectedImage(null);
                setPostType("");
                alert("Post added successfully!");
            })
            .catch(error => {
                alert("Error adding post");
            });
    };
    useEffect(() => {

        axios.post(`${USERURL2}`, {email: email})
        .then(response => {
            if(response.data && response.data.isAdmin) {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
        })
        .catch(error => {
            console.error("Error checking admin status:", error);
        });
}, [user]);

  useEffect(() => {

    axios.post(`${USERURLSTU}`, {email: email})
    .then(response => {
        if(response.data && response.data.isAdminStu) {
            setIsAdminStu(true);
        } else {
            setIsAdminStu(false);
        }
    })
    .catch(error => {
        console.error("Error checking admin status:", error);
    });
  }, [user]);

  useEffect(() => {
    axios.post(`${MODERADORURL}`, { email: email })
      .then(response => {
        if (response.data && response.data.isModerador) {
          setIsModerador(true);
        } else {
          setIsModerador(false);
        }
      })
      .catch(error => {
        console.error("Error checking moderator status:", error);
      });
  }, [user]);

  useEffect(() => {
    axios.post(`${MODERADORURLSTU}`, { email: email })
      .then(response => {
        if (response.data && response.data.isModeradorStu) {
          setIsModeradorStu(true);
        } else {
          setIsModeradorStu(false);
        }
      })
      .catch(error => {
        console.error("Error checking moderator status:", error);
      });
  }, [user]);


    return (
        <view>
            <section>
                <Header />
                <div className="main-content pt-7 right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                                <div className="card-body p-0"></div>
                                <div className="card-body p-0 mt-3 position-relative">
                                    <figure className="avatar position-absolute ms-2 mt-1 top-5">
                                        <img src={picture} alt="avatar" referrerPolicy="no-referrer" className="shadow-sm rounded-circle w30" />
                                    </figure>
                                    <ReactQuill
                                        value={postContent}
                                        onChange={setPostContent}
                                        placeholder="Escribe la noticia aquí"
                                        modules={{
                                            toolbar: [
                                                [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                                                [{size: []}],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{'list': 'ordered'}, {'list': 'bullet'}],
                                                ['link'],
                                                ['clean']
                                            ],
                                        }}
                                    />
                                </div>
                                <div className="card-body d-flex p-0 mt-1" style={{ justifyContent: 'space-between', marginTop: '12px' }} >

                                    <label htmlFor="fileInput" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 cursor-pointer">
                                        <i className="font-md text-success feather-image me-2"></i>
                                        <span className="d-none-xs">Photo</span>
                                    </label>
                                    <input 
                                        id="fileInput"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className="d-none"
                                    />
                                    {selectedImageName && <span className="ms-3">Imagen seleccionada: {selectedImageName}</span>}

                                    {/* Dropdown for post type selection */}
                                    <select 
                                        value={postType} 
                                        onChange={(e) => setPostType(e.target.value)} 
                                        className="form-select"
                                        style={{ maxWidth: '250px' }}
                                    >
                                        <option value="">Seleccionar tipo</option>
                                        {(isAdmin || isModerador) && <option value="DazAlert">DazAlert</option>}
                                        {(isAdminStu || isModeradorStu) && <option value="Estudio">Estudio</option>}
                                        {isAdmin && <option value="General">General</option>}
                                    </select>

                                    <button 
                                        class={`bg-current text-center text-white font-xsss fw-600 p-1 w175 rounded-3 d-inline-block  ${postContent.trim() ? '' : 'disabled'}`}
                                        onClick={handlePostSubmit}
                                       >
                                        Subir noticia
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Appfooter />
            </section>
        </view>
    )
}

export default AddPost;
