import React, { useState } from 'react';
import moment from 'moment'; // Import moment library for date formatting

const Popup = ({ rowData = {}, onSave, onDelete, onClose }) => {
    const [newEntryData, setNewEntryData] = useState({ ...rowData });
    const handleChange = (e) => {
        const { name, value } = e.target;
        // Update the newEntryData state with the changed value
        setNewEntryData({ ...newEntryData, [name]: value });
    };
  
    const handleSave = () => {
        onSave(newEntryData); // Pass the edited data to the onSave function
    };

    const handleDelete = () => {
        onDelete();
        onClose(); // Close the popup after deleting
    };

    const todayDate = moment().format('MM/DD/YY');

    const popupOverlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    };

    const popupStyle = {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '5px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
        zIndex: 1100,
    };

    const labelStyle = {
        display: 'block',
        marginBottom: '5px',
        width: '150px', // Fixed width for labels
    };
    const labelStyle2 = {
        display: 'block',
        marginBottom: '2px',
        fontSize: '0.8rem',
        width: '150px', // Fixed width for labels
    };

    const inputStyle = {
        width: '100%',
        padding: '8px',
        borderRadius: '3px',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
        marginBottom: '10px',
    };

    const buttonContainerStyle = {
        textAlign: 'right',
    };

    const buttonStyle = {
        padding: '8px 20px',
        marginLeft: '0px',
        backgroundColor: '#1600FF',
        color: '#fff',
        border: 'none',
        borderRadius: '3px',
        cursor: 'pointer',
    };

    const buttonStyle2 = {
        padding: '8px 20px',
        marginLeft: '10px',
        backgroundColor: 'grey',
        color: '#fff',
        border: 'none',
        borderRadius: '3px',
        cursor: 'pointer',
    };

    const deleteButtonStyle = {
        width: '100%',
        padding: '8px 20px',
        marginLeft: '0px',
        backgroundColor: '#FF0000',
        color: '#fff',
        border: 'none',
        borderRadius: '3px',
        cursor: 'pointer',
    };

    return (
        <div style={popupOverlayStyle}>
            <div style={popupStyle}>
                {rowData.new && (
                    <h2>Agregar datos</h2>
                )}
                {!rowData.new && (
                    <div>
                        <h2>Editar Datos</h2>
                        <button style={deleteButtonStyle} onClick={handleDelete}>Eliminar</button>
                    </div>
                )}
                <label style={labelStyle}>Fecha:</label>
                <text style={labelStyle2}>(formato mes/día/año - ej: 05/15/24)</text>
                <input
                    type="text"
                    name="timestamp"
                    value={newEntryData.timestamp || todayDate} // Use today's date as placeholder
                    onChange={handleChange}
                    style={inputStyle}
                    disabled={!rowData.new}
                />
                <label style={labelStyle}>Precio de Bitcoin:</label>
                <input
                    type="text"
                    name="precio"
                    value={newEntryData.precio}
                    onChange={handleChange}
                    style={inputStyle}
                />
                <label style={labelStyle}>Inversión:</label>
                <input
                    type="text"
                    name="invercion"
                    value={newEntryData.invercion}
                    onChange={handleChange}
                    style={inputStyle}
                />
                <div style={buttonContainerStyle}>
                    <button style={buttonStyle} onClick={handleSave}>Guardar</button>
                    <button style={buttonStyle2} onClick={onClose}>Cancelar</button>
                </div>
            </div>
        </div>
    );
};

export default Popup;
