import React from "react";

export const PageLoader = () => {

  return (
    <div className="loader">
      <video  id ="myVideo" className="video" autoPlay loop muted  style={{ width: '50%', height: '50%' }}>
        <source src="assets/video/load.mp4" type="video/mp4"/>
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
