import React from "react";
import { NavLink } from "react-router-dom";


export const NavBarBrand = () => {

  const getLogoSource = () => {
    const {theme} = localStorage;

    //console.log("theme")
    //console.log(theme)
    if (theme === "theme-dark") {
      return "assets/images/DazLogo_1.png";
    } else if (theme === "theme-light") {
      return "assets/images/DazLogo_1.png";
    }
    // Return a default logo image source if the theme is not recognized
    return "assets/images/DazLogo_1.png";
  };

  return (
    <div className="nav-bar__brand">
      <NavLink to="/about">
        <img
          className="mt-1"//"nav-bar__logo"
          src={getLogoSource()}
          alt="Daz Alert Logo"
          //width="220"
          //height="123"
          width={200}
            height={100}
        />
      </NavLink>
    </div>
  );
};
