import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { NavBar } from "../components/navigation/desktop/nav-bar";
import { MobileNavBar } from "../components/navigation/mobile/mobile-nav-bar";
import { PageLayout } from "../components/page-layout";

const CallbackPage = () => {
  const { error, loginWithRedirect } = useAuth0();
  const [retryAttempt, setRetryAttempt] = useState(0); // Track retry attempts

  //("CallbackPage component rendering");

  useEffect(() => {
    if (error && retryAttempt === 0) {
      setRetryAttempt(retryAttempt + 1); // Increment retry attempt count
      loginWithRedirect(); // Retry the action
    } else if (error && retryAttempt === 1) {
      window.location.href = "/about"; // Redirect to /about page
    }
  }, [error, loginWithRedirect, retryAttempt]);

  if (error && retryAttempt === 0) {
    return (
      <PageLayout>
        <div className="content-layout">
          <h1 id="page-title" className="content__title">
            Error
          </h1>
          <div className="content__body">
            <p id="page-description">
              <span>{error.message} </span>
              <span>
                Redireccionando a Acerca de Nosotros...{" "}
              </span>
            </p>
          </div>
        </div>
      </PageLayout>
    );
  }

  return (
    <div className="page-layout">
      <NavBar />
      <MobileNavBar />
      <div className="page-layout__content" />
    </div>
  );
};
export default CallbackPage;
