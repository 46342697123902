import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Darkbutton from "./Darkbutton";
import {useAuth0} from "@auth0/auth0-react";


const Header = () => { 

  const auth0 = useAuth0();
  const {user} = auth0;
  const { picture, email} = user;
  const [counter, setCounter] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isModerador, setIsModerador] = useState(false)
  const [isAdminStu, setIsAdminStu] = useState(false);
  const [isModeradorStu, setIsModeradorStu] = useState(false)
  const USERURL = "https://dazapis.herokuapp.com/checkadmin"
  const MODERADORURL = "https://dazapis.herokuapp.com/checkmoderador";
  const USERURLSTU = "https://dazapis.herokuapp.com/checkadminStu"
  const MODERADORURLSTU = "https://dazapis.herokuapp.com/checkmoderadorStu";


  const bgMiniGradientStyle1 = {
    background: 'linear-gradient(to right,#1e6091, #184e77)',
    /* Add more gradient stops or customize as needed */
  };

  const bgMiniGradientStyle2 = {
    background: 'linear-gradient(to right,#0f80aa, #0353a4)',
    /* Add more gradient stops or customize as needed */
  };
  const bgMiniGradientStyle3 = {
    background: 'linear-gradient(to right,#168aad, #1a759f)',
    /* Add more gradient stops or customize as needed */
  };
  const bgMiniGradientStyle4 = {
    background: 'linear-gradient(to right,#52b69a, #34a0a4)',
    /* Add more gradient stops or customize as needed */
  };

  const bgMiniGradientStyle5 = {
    background: 'linear-gradient(to right, #52b69a, #76c893)',
    /* Add more gradient stops or customize as needed */
  };
  
  const bgMiniGradientStyle6 = {
    background: 'linear-gradient(to right,#99d98c, #76c893)',
    /* Add more gradient stops or customize as needed */
  };

  const bgMiniGradientStyle7 = {
    background: 'linear-gradient(to right, #92C785, #a4d973)',
    /* Add more gradient stops or customize as needed */
};
const bgMiniGradientStyle8 = {
  background: 'linear-gradient(to right, #a4d973, #bfe456)',
  /* Add more gradient stops or customize as needed */
};

const bgMiniGradientStyle9 = {
  background: 'linear-gradient(to right, #bfe456, #D0DB50)', // Different color values while maintaining the same gradient style
  /* Add more gradient stops or customize as needed */
};

const bgMiniGradientStyle10 = {
  background: 'linear-gradient(to right, #D0DB50, #E5FF6F)', // Different color values while maintaining the same gradient style
  /* Add more gradient stops or customize as needed */
};


    const getLogoSource = () => {
      const {theme} = localStorage;
      if (theme === "theme-dark") {
        return "assets/images/DazLogo_2.png";
      } else if (theme === "theme-light") {
        return "assets/images/DazLogo_1.png";
      }
      // Return a default logo image source if the theme is not recognized
      return "assets/images/DazLogo_1.png";
    };
  

  const handleButtonClick = () => {
    setCounter(prevCounter => prevCounter + 1);
    //console.log(counter);
  };

  useEffect(() => {

        axios.post(`${USERURL}`, {email: email})
        .then(response => {
            if(response.data && response.data.isAdmin) {
                setIsAdmin(true);
            } else {
                setIsAdmin(false);
            }
        })
        .catch(error => {
            console.error("Error checking admin status:", error);
        });
}, [user]);

  useEffect(() => {

    axios.post(`${USERURLSTU}`, {email: email})
    .then(response => {
        if(response.data && response.data.isAdminStu) {
            setIsAdminStu(true);
        } else {
            setIsAdminStu(false);
        }
    })
    .catch(error => {
        console.error("Error checking admin status:", error);
    });
  }, [user]);

  useEffect(() => {
    axios.post(`${MODERADORURL}`, { email: email })
      .then(response => {
        if (response.data && response.data.isModerador) {
          setIsModerador(true);
        } else {
          setIsModerador(false);
        }
      })
      .catch(error => {
        console.error("Error checking moderator status:", error);
      });
  }, [user]);

  useEffect(() => {
    axios.post(`${MODERADORURLSTU}`, { email: email })
      .then(response => {
        if (response.data && response.data.isModeradorStu) {
          setIsModeradorStu(true);
        } else {
          setIsModeradorStu(false);
        }
      })
      .catch(error => {
        console.error("Error checking moderator status:", error);
      });
  }, [user]);


   

    return (
      <div className="nav-header bg-white shadow-xs border-0 mb-4" >
        <div className="nav-top">
          <Link to="/about">
          <img
            src={getLogoSource()}
            width={220}
            height={123}
            className="mt-1"
            alt="logo"
          />
           
          </Link>


        </div>


    
        <Link
          to="/defaultnoti"
          className="p-2 pointer text-center ms-auto menu-icon"
        >
          <i className="feather-send font-xl text-current"></i>
        </Link>
        <Link
          to="/helpbox"
          className="p-2 text-center ms-3 menu-icon chat-active-btn"
        >
          <i className="feather-help-circle font-xl text-current"></i>
        </Link>
        <Darkbutton  handleButtonClick={handleButtonClick} />
        <Link to="/defaultsettings" className="p-0 ms-3 menu-icon">
          <img src={picture} alt="user" referrerPolicy="no-referrer" className="w40 mt--1" />
        </Link>

        <nav className='navigation scroll-bar'>
          <div className="container ps-0 pe-0">
            <div className="nav-content">
              <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
                <div className="nav-caption fw-600 font-xssss text-grey-500">
                  <span>DazAlert </span>
                </div>
                <ul className="mb-1 top-content">
                  <li className="logo d-none d-xl-block d-lg-block"></li>
                  <li>
                    <Link to="/about" className="nav-content-bttn open-font">
                      <i className="feather-home btn-round-md  t me-3"  style={bgMiniGradientStyle1}></i>
                      <span>Acerca de nosotros</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/noticias" className="nav-content-bttn open-font">
                      <i className="feather-tv btn-round-md  me-3"  style={bgMiniGradientStyle2}></i>
                      <span>Noticias</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/notificacion"
                      className="nav-content-bttn open-font"
                    >
                      <i className="feather-bell  btn-round-md  me-3" style={bgMiniGradientStyle3} ></i>
                      <span>Alertas</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/paquetes" className="nav-content-bttn open-font">
                      <i className="feather-package btn-round-md  me-3" style={bgMiniGradientStyle4}></i>
                      <span>Paquetes</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/graficaBTC" className="nav-content-bttn open-font">
                      <i className="feather-bar-chart-2 btn-round-md  me-3" style={bgMiniGradientStyle5}></i>
                      <span>BTC</span>
                    </Link>
                  </li>
                  {isAdmin || isModerador || isAdminStu || isModeradorStu ? (
                    <li>
                      <Link to="/addpost" className="nav-content-bttn open-font">
                        <i className="feather-plus-circle btn-round-md bg-ora me-3" style={bgMiniGradientStyle6}></i>
                        <span>Agregar post</span>
                      </Link>
                    </li>
                  ) : null}

                  {isAdmin && (
                  <li>
                    <Link to="/suscription" className="nav-content-bttn open-font">
                      <i className="feather-layers btn-round-md  me-3" style={bgMiniGradientStyle7}></i>
                      <span>Suscripciones</span>
                    </Link>
                  </li>
                  )}
                  {isAdmin || isAdminStu ? (
                  <li>
                    <Link to="/admins" className="nav-content-bttn open-font">
                      <i className="feather-user btn-round-md  me-3" style={bgMiniGradientStyle8}></i>
                      <span>Nuevos admin</span>
                    </Link>
                  </li>
                  ): null}
                  {isAdmin && (
                  <li>
                    <Link to="/cambio" className="nav-content-bttn open-font">
                      <i className="feather-dollar-sign btn-round-md  me-3" style={bgMiniGradientStyle9}></i>
                      <span>Cambios en pago</span>
                    </Link>
                  </li>
                  )}
                  {isAdmin && (
                  <li>
                    <Link to="/btc" className="nav-content-bttn open-font">
                      <i className="feather-database btn-round-md  me-3" style={bgMiniGradientStyle10}></i>
                      <span>Datos BTC</span>
                    </Link>
                  </li>
                  )}
                  
                  
                </ul>
              </div>

             
            </div>
          </div>
        </nav>

      </div>
    );
  
}


export default Header;


