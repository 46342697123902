import React, { Component } from 'react';
import axios from "axios";
import he from 'he'; // Import he library for HTML entity decoding


class Postview extends Component {
    state = {
        isOpen: false,
        isActive: false,
        likes: 0,
        comments: [],
        isLiked: false,
        commentText: '',
        showCommentInput: false
    };

    componentDidMount() {
        axios.get(`https://dazapis.herokuapp.com/getlikes?postid=${this.props.id}`)
            .then(response => {
                if (response.data.status === "success") {
                    this.setState({ likes: response.data.count });
                }
            })
            .catch(error => {
               // console.log("Error fetching like count:", error);
            });

        axios.get(`https://dazapis.herokuapp.com/getcomments?postid=${this.props.id}`)
            .then(response => {
                if (response.data.status === "success") {
                    this.setState({ comments: response.data.comments });
                }
            })
            .catch(error => {
                //console.log("Error fetching comments:", error);
            });
    }

    toggleCommentInput = () => {
        this.setState(prevState => ({ showCommentInput: !prevState.showCommentInput }));
    }

    handleLike = () => {
        this.setState({ isLiked: !this.state.isLiked }, () => {
            axios.post('https://dazapis.herokuapp.com/likepost', {
                postid: this.props.id,
                userid: this.props.userEmail,
                isLiked: this.state.isLiked
            })
                .then(response => {
                    if (response.data.status === "success") {
                        axios.get(`https://dazapis.herokuapp.com/getlikes?postid=${this.props.id}`)
                            .then(response => {
                                if (response.data.status === "success") {
                                    this.setState({ likes: response.data.count });
                                }
                            })
                            .catch(error => {
                                //console.log("Error fetching like count:", error);
                            });
                    }
                });
        });
    };

    handleComment = (text) => {
        if (!text.trim()) return;

        const newComment = {
            username: this.props.userNickname,
            text: text,
            picture: this.props.picture
        };

        this.setState({
            comments: [...this.state.comments, newComment],
            commentText: ''
        });

        axios.post('https://dazapis.herokuapp.com/addcomment', {
            postid: this.props.id,
            userid: this.props.userEmail,
            text: text,
            picture: this.props.picture
        })
        .catch(error => {
            //console.log("Error adding comment:", error);
        });
    };

    handleDelete = () => {
        const isConfirmed = window.confirm("Are you sure you want to delete this post?");
        if (!isConfirmed) return;

        axios.post('https://dazapis.herokuapp.com/deletepost', {
            postid: this.props.id
        })
        .then(response => {
            if (response.data.success === "Post deleted successfully") {
                console.log("Post deleted successfully");
                this.props.onPostRemoved(this.props.id);
            }
        })
        .catch(error => {
            //console.log("Error deleting post:", error);
        });
    };

    toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
    toggleActive = () => this.setState({ isActive: !this.state.isActive });

    render() {
        const { users, time, des, avater, postimage, id, userEmail, picture, autor, userNickname, nickname } = this.props;

        const decodedContent = he.decode(des);
        
        const menuClass = `${this.state.isOpen ? " show" : ""}`;
        const emojiClass = `${this.state.isActive ? " active" : ""}`;

        return (
            <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                <div className="card-body p-0 d-flex">
                    <figure className="avatar me-3"><img src={avater} alt="avatar" className="shadow-sm rounded-circle w45" /></figure>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">{nickname} <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{time}</span></h4>
                    {autor === userEmail && (
                        <div className="ms-auto pointer" onClick={this.handleDelete}><i className="ti-trash text-grey-900 btn-round-md bg-greylight font-xss"></i></div>
                    )}
                </div>

                <div className="card-body d-block p-0 mb-3">
                    <div className="row ps-2 pe-2">
                        <div className="col-sm-12 p-1">
                            <div dangerouslySetInnerHTML={{ __html: decodedContent }} />
                        </div>
                    </div>
                </div>

                {postimage && (
                    <div className="card-body d-block p-0 mb-3">
                        <div className="row ps-2 pe-2">
                            <div className="col-sm-12 p-1">
                                <img src={`data:image/jpeg;base64,${postimage}`} className="rounded-3 w-100" alt="post" />
                            </div>
                        </div>
                    </div>
                )}

                <div className="card-body d-flex p-0">
                    <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2" onClick={this.handleLike}>
                        <i className="feather-thumbs-up text-white bg-primary-gradiant me-1 btn-round-xs font-xss"></i> {this.state.likes} Like
                    </div>

                    <div className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss" onClick={this.toggleCommentInput}>
                        <i className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i>
                        <span className="d-none-xss">{this.state.comments.length} Comment</span>
                    </div>
                </div>

                {this.state.showCommentInput && (
                    <div>
                        <div className="card-body p-0">
                            <br />
                            {this.state.comments.map((comment, index) => (
                                <div key={index} className="comment-item" style={{
                                    borderBottom: index !== this.state.comments.length - 1 ? '1px solid #e0e0e0' : 'none',
                                    paddingBottom: '10px',
                                    marginBottom: '10px'
                                }}>
                                    <div className="card-body p-0 d-flex">
                                        <figure className="avatar me-3">
                                            <img src={comment.picture} alt="User avatar" className="comment-avatar shadow-sm rounded-circle w35" />
                                        </figure>
                                        <h4 className="fw-700 text-grey-900 font-xssss mt-2">{comment.nickname}</h4>
                                    </div>
                                    <div className="row ps-2 pe-2">
                                        <div className="col-sm-12 p-1">
                                            <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-1">{comment.text}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="card-body p-0 mt-3 position-relative">
                            <figure className="avatar position-absolute ms-2 mt-1 top-5">
                                <img src={picture} alt="avatar" referrerPolicy="no-referrer" className="shadow-sm rounded-circle w30" />
                            </figure>
                            <textarea
                                name="comment"
                                value={this.state.commentText}
                                onChange={e => this.setState({ commentText: e.target.value })}
                                className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                placeholder="Agregar un comentario..."
                            ></textarea>
                        </div>
                        <button className="btn w200 border-0 bg-primary-gradiant p-3 text-white fw-400 rounded-3 d-inline-block font-xssss" onClick={() => this.handleComment(this.state.commentText)}>Post</button>
                    </div>
                )}
            </div>
        );
    }
}

export default Postview;
