

import Header from "../components/Header";
import Appfooter from "../components/Appfooter";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const Paquetes = () => {

    const {user} = useAuth0();
    const { email } = user;
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [prices, setPrices] = useState([]);
    const [studioEnabled, setStudioEnabled] = useState(0);
    const getPrices = "https://dazapis.herokuapp.com/getprices";
    const GET_STUDIO_STATUS_URL = 'https://dazapis.herokuapp.com/getStudioStatus';



    useEffect(() => {
        // Fetching status as soon as component mounts
        checkPruebaStatus();
        studioStat()
      }, [email]);
    
      const studioStat = async (e) => {
        axios.get(GET_STUDIO_STATUS_URL)
         .then(response => {
             setStudioEnabled(response.data.enabled);
         })
         .catch(error => {
             console.error("Error fetching studio status:", error);
         });
    
     } 
       

    useEffect(() => {
        const fetchPrices = async () => {
            setLoading(true);
            try {
                const response = await axios.get(getPrices);
                setPrices(response.data); // Assuming the response.data is an array of prices
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchPrices();
    }, []);
    const checkPruebaStatus = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`https://dazapis.herokuapp.com/checkprueba/${email}`);//(`https://dazapis.herokuapp.com/checkprueba/${email}`);

            if (response.ok) {
                const data = await response.json();
                setResult(data);
            } else {
                const errorData = await response.json();
                setError(errorData.error);
            }
        } catch (err) {
            setError(`An error occurred: ${err.message}`);
        } finally {
            setLoading(false);
        }
    };

    const getPriceById = (id) => {
        const priceObject = prices.find((price) => price.id === id);
        return priceObject ? priceObject.precio : null;
    };

    const handleLinkClick = () => {
        const data = {
          price: getPriceById(23),
          subscription: "Prueba Gratuita de 7 días"
        };
    
        // Save to sessionStorage
        sessionStorage.setItem('price', data.price);
        sessionStorage.setItem('subscription', data.subscription);
      };

    const handleLinkClick2 = () => {
        const data = {
            price: getPriceById(884),
            subscription: "Acesso ilimitado por un año"
        };
            
        // Save to sessionStorage
        sessionStorage.setItem('price', data.price);
        sessionStorage.setItem('subscription', data.subscription);
      };
    

    const handleLinkClick3 = () => {
            const data = {
                price:  getPriceById(985),
                subscription: "Acesso limitado por un año"
            }
       
        // Save to sessionStorage
        sessionStorage.setItem('price', data.price);
        sessionStorage.setItem('subscription', data.subscription);
      };

    const handleLinkClick4 = () => {
        const data = {
            price: getPriceById(456),
            subscription: "Acesso ilimitado por un mes"
        }
   
    // Save to sessionStorage
    sessionStorage.setItem('price', data.price);
    sessionStorage.setItem('subscription', data.subscription);
  };

    const handleLinkClick5 = () => {
    const data = {
        price: getPriceById(374),
        subscription: "Acesso limitado por un mes"
    }

    // Save to sessionStorage
    sessionStorage.setItem('price', data.price);
    sessionStorage.setItem('subscription', data.subscription);
    };

    const handleLinkClickStudio = () => {
        const data = {
          price: getPriceById(50),
          subscription: "Membresía Estudio"
        };
    
        // Save to sessionStorage
        sessionStorage.setItem('price', data.price);
        sessionStorage.setItem('subscription', data.subscription);
    };


    
    return (
        <section>
        <Header />

            <div className="main-content  right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">

                
                

                <div class="row">
                    <div class="col-lg-12">
                    <div class="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                        <div class="row">
                        <div class="col-lg-6">
                            <img src="assets/images/cripto.jpeg" alt="banner" class="w-100"/>
                        </div>
                        <div class="col-lg-6 ps-lg-5">
                            <h2 class="display1-size d-block mb-2 text-grey-900 fw-700">Planes de membresía</h2>
                            <p class="font-xssss fw-500 text-grey-500 lh-26">Planes de membresía anual y mensual</p>
                            <a href="#beneficios" class="btn w200 border-0 bg-primary-gradiant p-3 text-white fw-600 rounded-3 d-inline-block font-xssss">Ver Beneficios</a>
                        </div>
                        </div>
                    </div>
                    </div>

                



                <div class="card w-100 border-0 shadow-none bg-transparent mt-5">
                    <div data-accordion-component="Accordion" class="accodion-style--1 accordion">
                    {(result && result.prueba === false) && (
                    <div data-accordion-component="AccordionItem" class="card shadow-xss">
                        <div data-accordion-component="AccordionItemHeading" class="card-header" role="heading" aria-level="3">
                        <div id="accordion__heading-4" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-4" role="button" tabindex="0" class="accordion__button" data-accordion-component="AccordionItemButton">
                            <h5 class="fw-600 pt-2 pb-2 mb-0">Prueba Gratuita de 7 días</h5>
                        </div>
                        </div>
                        <div data-accordion-component="AccordionItemPanel" class="card-body" role="region" aria-labelledby="accordion__heading-4" id="accordion__panel-4" hidden="">
                        <div class="product-action flex-row align-items-center">
                        
                        <Link
                            to={{
                                pathname: "/cart",
                            }}
                            onClick={handleLinkClick}
                            className="add-to-cart bg-dark text-white fw-700 ps-lg-5 pe-lg-5 text-uppercase font-xssss float-right border-dark border rounded-3 border-size-md d-inline-block mt-0 p-2 text-center ls-3"
                        >
                            Agregar a carrito
                        </Link>
                        </div>
                        <p><span style={{ fontWeight: 'bold' }}>Acesso limitado:</span> {getPriceById(23)}$ por 7 días </p>
                        
                        
                       
                        
                        
                        </div>
                        </div>
                    )}

                    {studioEnabled && (
                    <div data-accordion-component="AccordionItem" class="card shadow-xss">
                        <div data-accordion-component="AccordionItemHeading" class="card-header" role="heading" aria-level="3">
                        <div id="accordion__heading-4" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-4" role="button" tabindex="0" class="accordion__button" data-accordion-component="AccordionItemButton">
                            <h5 class="fw-600 pt-2 pb-2 mb-0">Membresía de Estudio</h5>
                        </div>
                        </div>
                        <div data-accordion-component="AccordionItemPanel" class="card-body" role="region" aria-labelledby="accordion__heading-4" id="accordion__panel-4" hidden="">
                        <div class="product-action flex-row align-items-center">
                        
                        <Link
                            to={{
                                pathname: "/cart",
                            }}
                            onClick={handleLinkClickStudio}
                            className="add-to-cart bg-dark text-white fw-700 ps-lg-5 pe-lg-5 text-uppercase font-xssss float-right border-dark border rounded-3 border-size-md d-inline-block mt-0 p-2 text-center ls-3"
                        >
                            Agregar a carrito
                        </Link>
                        </div>
                        <p><span style={{ fontWeight: 'bold' }}>Acesso Estudio:</span> {getPriceById(50)}$ por duración del Estudio </p>
                        
                        
                       
                        
                        
                        </div>
                        </div>
                    )}

                    <div data-accordion-component="AccordionItem" class="card shadow-xss">
                        <div data-accordion-component="AccordionItemHeading" class="card-header" role="heading" aria-level="3">
                        <div id="accordion__heading-4" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-4" role="button" tabindex="0" class="accordion__button" data-accordion-component="AccordionItemButton">
                            <h5 class="fw-600 pt-2 pb-2 mb-0">Planes de membresía anual</h5>
                        </div>
                        </div>
                        <div data-accordion-component="AccordionItemPanel" class="card-body" role="region" aria-labelledby="accordion__heading-4" id="accordion__panel-4" hidden="">
                        <div class="product-action flex-row align-items-center">
                        
                        <Link
                            to={{
                                pathname: "/cart"
                            }}
                            onClick={handleLinkClick2}
                            className="add-to-cart bg-dark text-white fw-700 ps-lg-5 pe-lg-5 text-uppercase font-xssss float-right border-dark border rounded-3 border-size-md d-inline-block mt-0 p-2 text-center ls-3"
                        >
                            Agregar a carrito
                        </Link>
                        </div>
                        <p><span style={{ fontWeight: 'bold' }}>Acesso ilimitado:</span> {getPriceById(884)}$ al año </p>
                        
                        
                        <div class="product-action flex-row align-items-center">
                        <Link
                            to={{
                                pathname: "/cart"
                            }}
                            onClick={handleLinkClick3}
                            className="add-to-cart bg-dark text-white fw-700 ps-lg-5 pe-lg-5 text-uppercase font-xssss float-right border-dark border rounded-3 border-size-md d-inline-block mt-0 p-2 text-center ls-3"
                        >
                            Agregar a carrito
                        </Link>
                        <p><span style={{ fontWeight: 'bold' }}>Acesso limitado:</span> {getPriceById(985)}$ al año</p>
                        </div>
                        
                        
                        </div>
                        </div>


                        <div data-accordion-component="AccordionItem" id="beneficios" class="card shadow-xss">
                        <div data-accordion-component="AccordionItemHeading" class="card-header" role="heading" aria-level="3">
                            <div id="accordion__heading-5" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-5" role="button" tabindex="0" class="accordion__button" data-accordion-component="AccordionItemButton">
                            <h5 class="fw-600 pt-2 pb-2 mb-0">Planes de membresía mensual</h5>
                            </div>
                        </div>
                        <div data-accordion-component="AccordionItemPanel" class="card-body" role="region" aria-labelledby="accordion__heading-5" id="accordion__panel-5" hidden="">
                        <div class="product-action flex-row align-items-center">
                        
                        <Link
                            to={{
                                pathname: "/cart"
                            }}
                            onClick={handleLinkClick4}
                            className="add-to-cart bg-dark text-white fw-700 ps-lg-5 pe-lg-5 text-uppercase font-xssss float-right border-dark border rounded-3 border-size-md d-inline-block mt-0 p-2 text-center ls-3"
                        >
                            Agregar a carrito
                        </Link>
                        </div>
                        <p><span style={{ fontWeight: 'bold' }}>Acesso ilimitado:</span> {getPriceById(456)}$ al mes </p>
                        
                        
                        <div class="product-action flex-row align-items-center">
                        <Link
                            to={{
                                pathname: "/cart"
                            }}
                            onClick={handleLinkClick5}
                            className="add-to-cart bg-dark text-white fw-700 ps-lg-5 pe-lg-5 text-uppercase font-xssss float-right border-dark border rounded-3 border-size-md d-inline-block mt-0 p-2 text-center ls-3"
                        >
                            Agregar a carrito
                        </Link>
                        <p><span style={{ fontWeight: 'bold' }}>Acesso limitado:</span> {getPriceById(374)}$ al mes</p>
                        </div>
                        </div>
                    </div>
                    <div data-accordion-component="AccordionItem" class="card shadow-xss">
                        <div data-accordion-component="AccordionItemHeading" class="card-header" role="heading" aria-level="3">
                            <div id="accordion__heading-5" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-5" role="button" tabindex="0" class="accordion__button" data-accordion-component="AccordionItemButton">
                            <h5 class="fw-600 pt-2 pb-2 mb-0">Beneficios del acceso limitado:</h5>
                            </div>
                        </div>
                        <div data-accordion-component="AccordionItemPanel" class="card-body" role="region" aria-labelledby="accordion__heading-5" id="accordion__panel-5" hidden="">
                        <p>• Recepción de alertas personalizadas de trading en tiempo real para una sola divisa.</p>
                        <p>• Acceso a nuestro centro de educación de trading en línea, que incluye webinars y artículos informativos.</p>
                        <p>• Soporte técnico básico en horario comercial.</p>
                        

                        </div>
                    </div>
                    <div data-accordion-component="AccordionItem" class="card shadow-xss">
                        <div data-accordion-component="AccordionItemHeading" class="card-header" role="heading" aria-level="3">
                            <div id="accordion__heading-5" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-5" role="button" tabindex="0" class="accordion__button" data-accordion-component="AccordionItemButton">
                            <h5 class="fw-600 pt-2 pb-2 mb-0">Beneficios del acceso ilimitado:</h5>
                            </div>
                        </div>
                        <div data-accordion-component="AccordionItemPanel" class="card-body" role="region" aria-labelledby="accordion__heading-5" id="accordion__panel-5" hidden="">
                        <p>• Recepción de alertas personalizadas de trading en tiempo real para múltiples divisas.</p>
                        <p>• Acceso completo a nuestro centro de educación de trading en linea, que incluye webinars, cursos en linea y artículos informativos.</p>
                        <p>• Soporte técnico premium, incluyendo asistencia en vivo con nuestro equipo de expertos en trading.</p>
                        

                        </div>
                    </div>

                    <div data-accordion-component="AccordionItem" class="card shadow-xss">
                        <div data-accordion-component="AccordionItemHeading" class="card-header" role="heading" aria-level="3">
                            <div id="accordion__heading-5" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-5" role="button" tabindex="0" class="accordion__button" data-accordion-component="AccordionItemButton">
                            <h5 class="fw-600 pt-2 pb-2 mb-0">Acerca de la prueba gratuita de 7 días:</h5>
                            </div>
                        </div>
                        <div data-accordion-component="AccordionItemPanel" class="card-body" role="region" aria-labelledby="accordion__heading-5" id="accordion__panel-5" hidden="">
                        <p>• Recepción de alertas personalizadas de trading en tiempo real para UNA sola divisa.</p>
                        <p>• Acceso completo a nuestro centro de educación de trading en linea, que incluye webinars, cursos en linea y artículos informativos.</p>
                        <p>• Tiempo de prueba gratuita: 7 días</p>
                        <p>• No tiene costo</p>
                        
                        

                        </div>
                    </div>
                
                
                    
                
                </div>
                </div>
                </div>
                </div>
            </div>
            </div>
            <Appfooter />
        </section>
     
    )
}

export default Paquetes
