

import Header from "../components/Header";
import Appfooter from "../components/Appfooter";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect} from "react";
import axios from 'axios';



const Admins = () => {
       
    const {user} = useAuth0();
    const { name, picture, email } = user;

    const [users, setUsers] = useState([]);
    const [usersStu, setUsersStu] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchTerm2, setSearchTerm2] = useState('');
    const [studioEnabled, setStudioEnabled] = useState(0);
    const [currentUser, setCurrentUser] = useState(null);
    const [currentUser2, setCurrentUser2] = useState(null);

    const USERURL = "https://dazapis.herokuapp.com/getusers"
    const USERURLStu = "https://dazapis.herokuapp.com/getusersStu"
    const USERURL2 = "https://dazapis.herokuapp.com/grantadmin"
    const USERURL3 = "https://dazapis.herokuapp.com/revokeadmin"
    const USERURL4 = "https://dazapis.herokuapp.com/grantmod"
    const USERURL5 = "https://dazapis.herokuapp.com/revokemod"
    const GRANT_MODERATOR_FOR_ESTUDIO_URL = 'https://dazapis.herokuapp.com/grantModStu';
    const REVOKE_MODERATOR_FOR_ESTUDIO_URL = 'https://dazapis.herokuapp.com/revokeModStu';
    const GRANT_ADMIN_FOR_ESTUDIO_URL = 'https://dazapis.herokuapp.com/grantAdminStu';
    const REVOKE_ADMIN_FOR_ESTUDIO_URL = 'https://dazapis.herokuapp.com/revokeAdminStu';
    const GET_STUDIO_STATUS_URL = 'https://dazapis.herokuapp.com/getStudioStatus';
    const TOGGLE_STUDIO_STATUS_URL = 'https://dazapis.herokuapp.com/toggleStudioStatus';




    useEffect(() => {
        // Assuming you have an API endpoint called "/getUsers"
        axios.get(USERURL)
        .then(response => {
            setUsers(response.data);
            const currentUserData = response.data.find(u => u.email === email);
            setCurrentUser(currentUserData);
        })
        .catch(error => {
            console.error("Error fetching users:", error);
        });
         // Assuming you have an API endpoint called "/getUsers"
         axios.get(USERURLStu)
         .then(response => {
             setUsersStu(response.data);
             const currentUserData2 = response.data.find(u => u.email === email);
             setCurrentUser2(currentUserData2);
         })
         .catch(error => {
             console.error("Error fetching users:", error);
         });
         axios.get(GET_STUDIO_STATUS_URL)
         .then(response => {
             setStudioEnabled(response.data.enabled);
         })
         .catch(error => {
             console.error("Error fetching studio status:", error);
         });

    }, [email]); // Empty dependency array means this useEffect will run once when the component mounts

    const handleAddAdmin = (userEmail) => {
        axios.post(USERURL2, { email: userEmail })
            .then(response => {
                // Assuming the API returns the modified user
                const updatedUsers = users.map(u => {
                    if (u.email === userEmail) u.isAdmin = true;
                    return u;
                });
                setUsers(updatedUsers);
            })
            .catch(error => {
                console.error("Error granting admin rights:", error);
            });
    }
    const handleAddMod = (userEmail) => {
        axios.post(USERURL4, { email: userEmail })
            .then(response => {
                // Assuming the API returns the modified user
                const updatedUsers = users.map(u => {
                    if (u.email === userEmail) u.isModerador = true;
                    return u;
                });
                setUsers(updatedUsers);
            })
            .catch(error => {
                console.error("Error granting moderator rights:", error);
            });
    }
    const handleRemoveAdmin = (userEmail) => {
        axios.post(USERURL3, { email: userEmail })
            .then(response => {
                // Assuming the API returns the modified user
                const updatedUsers = users.map(u => {
                    if (u.email === userEmail) u.isAdmin = false;
                    return u;
                });
                setUsers(updatedUsers);
            })
            .catch(error => {
                console.error("Error revoking admin rights:", error);
            });
    }
    const handleRemoveMod = (userEmail) => {
        axios.post(USERURL5, { email: userEmail })
            .then(response => {
                // Assuming the API returns the modified user
                const updatedUsers = users.map(u => {
                    if (u.email === userEmail) u.isModerador = false;
                    return u;
                });
                setUsers(updatedUsers);
            })
            .catch(error => {
                console.error("Error revoking moderator rights:", error);
            });
    }
    const handleAddAdminStu = (userEmail) => {
        axios.post(GRANT_ADMIN_FOR_ESTUDIO_URL, { email: userEmail })
            .then(response => {
                const updatedUsersStu = usersStu.map(u => {
                    if (u.email === userEmail) u.isAdminStudio = true;
                    return u;
                });
                setUsersStu(updatedUsersStu);
            })
            .catch(error => {
                console.error("Error granting studio admin rights:", error);
            });
    }

    const handleAddModStu = (userEmail) => {
        axios.post(GRANT_MODERATOR_FOR_ESTUDIO_URL, { email: userEmail })
            .then(response => {
                const updatedUsersStu = usersStu.map(u => {
                    if (u.email === userEmail) u.isModStudio = true;
                    return u;
                });
                setUsersStu(updatedUsersStu);
            })
            .catch(error => {
                console.error("Error granting studio moderator rights:", error);
            });
    }

    const handleRemoveAdminStu = (userEmail) => {
        axios.post(REVOKE_ADMIN_FOR_ESTUDIO_URL, { email: userEmail })
            .then(response => {
                const updatedUsersStu = usersStu.map(u => {
                    if (u.email === userEmail) u.isAdminStudio = false;
                    return u;
                });
                setUsersStu(updatedUsersStu);
            })
            .catch(error => {
                console.error("Error revoking studio admin rights:", error);
            });
    }

    const handleRemoveModStu = (userEmail) => {
        axios.post(REVOKE_MODERATOR_FOR_ESTUDIO_URL, { email: userEmail })
            .then(response => {
                const updatedUsersStu = usersStu.map(u => {
                    if (u.email === userEmail) u.isModStudio = false;
                    return u;
                });
                setUsersStu(updatedUsersStu);
            })
            .catch(error => {
                console.error("Error revoking studio moderator rights:", error);
            });
    }
    
    const handleToggleStudio = () => {
        axios.post(TOGGLE_STUDIO_STATUS_URL)
            .then(response => {
                setStudioEnabled(response.data.new_enabled_status); // Update studio status in state

            })
            .catch(error => {
                console.error("Error toggling studio status:", error);
            });
    }



        // Calculate the counts
    const totalUsers = users.length;
    const totalAdmins = users.filter(user => user.isAdmin).length;
    const totalModerators = users.filter(user => user.isModerador).length;

    const totalUsersStu = usersStu.length;
    const totalAdminsStu = usersStu.filter(user => user.isAdminStudio).length;
    const totalModeratorsStu = usersStu.filter(user => user.isModStudio).length;
    

    return (
        <view>
       
        <section>
        <Header />
        <div className="main-content pt-7 right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                                <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">Miembros de DazAlert
                                <form action="#" class="pt-0 pb-0 ms-auto">
                                <div class="search-form-2 ms-2">
                                    <i class="ti-search font-xss"></i>
                                     {/* 2. Update the search term when the input field value changes. */}
                                        <input 
                                            type="text" 
                                            className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0" 
                                            placeholder="Buscar aquí." 
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                </div>
                                </form>
                                
                                </h2>
                            </div>

                                             {/* Styled Elements for Total Users, Admins, and Moderators */}
                                             <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                                        <div className="d-flex justify-content-around">
                                            <div className="text-center">
                                                <h3 className="fw-700 text-grey-900">{totalUsers}</h3>
                                                <p className="font-xsss text-grey-500 mb-0">Usuarios Totales</p>
                                            </div>
                                            <div className="text-center">
                                                <h3 className="fw-700 text-grey-900">{totalAdmins}</h3>
                                                <p className="font-xsss text-grey-500 mb-0">Administradores</p>
                                            </div>
                                            <div className="text-center">
                                                <h3 className="fw-700 text-grey-900">{totalModerators}</h3>
                                                <p className="font-xsss text-grey-500 mb-0">Moderadores</p>
                                            </div>
                                        </div>
                                    </div>

                                    {currentUser?.isAdmin && (
                            <div class="row ps-2 pe-2">
                            {
                                            users.filter(user => user.email.includes(searchTerm)).map(u => (
                                                <div className="col-md-4 col-sm-4 pe-2 ps-2" key={u.email}>
                                                    <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                                                        <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                                                            <div className="clearfix w-100"></div>
                                                            <h4 className="fw-700 font-xsss mt-0 mb-3">{u.email}</h4>
                                                            <div className="d-flex justify-content-between">
                                                            {u.isAdmin ? (
                                                            <button onClick={() => handleRemoveAdmin(u.email)} style={{ width: 'calc(50% - 5px)', margin: '0 5px' }
                                                            } className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-warning font-xsssss fw-700 ls-lg text-white">QUITAR ADMIN</button>
                                                            ) : (
                                                            <button onClick={() => handleAddAdmin(u.email)} style={{ width: 'calc(50% - 5px)', margin: '0 5px' }} className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white">AGREGAR ADMIN</button>
                                                            )}
                                                            {u.isModerador ? (
                                                            <button onClick={() => handleRemoveMod(u.email)} style={{ width: 'calc(50% - 5px)', margin: '0 5px' }} className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-warning font-xsssss fw-700 ls-lg text-white">QUITAR MODERADOR</button>
                                                            ) : (
                                                            <button onClick={() => handleAddMod(u.email)} style={{ width: 'calc(50% - 5px)', margin: '0 5px' }}  className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white">AGREGAR MODERADOR</button>
                                                            )}
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                        
                </div>
                                    )}
            </div>
         </div>

         {currentUser?.isAdmin && (
                                        <>
                                            <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                                                <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">
                                                    Activar Estudio
                                                        <button
                                                            className="btn btn-primary ms-auto"
                                                            onClick={handleToggleStudio}
                                                        >
                                                            {studioEnabled ? 'Cerrar Estudio' : 'Abrir Estudio'}
                                                        </button>
                                                
                                                </h2>
                                            </div>
                                            </>
                                    )
                                }
         

         {currentUser2?.isAdminStudio && studioEnabled && (
         <div class="row">
                        <div class="col-xl-12">
                            <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                                <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">Miembros de Estudio
                                <form action="#" class="pt-0 pb-0 ms-auto">
                                <div class="search-form-2 ms-2">
                                    <i class="ti-search font-xss"></i>
                                     {/* 2. Update the search term when the input field value changes. */}
                                        <input 
                                            type="text" 
                                            className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0" 
                                            placeholder="Buscar aquí." 
                                            value={searchTerm2}
                                            onChange={(e) => setSearchTerm2(e.target.value)}
                                        />
                                </div>
                                </form>
                                
                                </h2>
                            </div>

                                             {/* Styled Elements for Total Users, Admins, and Moderators */}
                                             <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                                        <div className="d-flex justify-content-around">
                                            <div className="text-center">
                                                <h3 className="fw-700 text-grey-900">{totalUsersStu}</h3>
                                                <p className="font-xsss text-grey-500 mb-0">Usuarios Totales</p>
                                            </div>
                                            <div className="text-center">
                                                <h3 className="fw-700 text-grey-900">{totalAdminsStu}</h3>
                                                <p className="font-xsss text-grey-500 mb-0">Administradores</p>
                                            </div>
                                            <div className="text-center">
                                                <h3 className="fw-700 text-grey-900">{totalModeratorsStu}</h3>
                                                <p className="font-xsss text-grey-500 mb-0">Moderadores</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                            <div class="row ps-2 pe-2">
                            {
                                            usersStu.filter(user => user.email.includes(searchTerm2)).map(u => (
                                                <div className="col-md-4 col-sm-4 pe-2 ps-2" key={u.email}>
                                                    <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                                                        <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                                                            <div className="clearfix w-100"></div>
                                                            <h4 className="fw-700 font-xsss mt-0 mb-3">{u.email}</h4>
                                                            <div className="d-flex justify-content-between">
                                                            {u.isAdminStudio ? (
                                                            <button onClick={() => handleRemoveAdminStu(u.email)} style={{ width: 'calc(50% - 5px)', margin: '0 5px' }
                                                            } className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-warning font-xsssss fw-700 ls-lg text-white">QUITAR ADMIN</button>
                                                            ) : (
                                                            <button onClick={() => handleAddAdminStu(u.email)} style={{ width: 'calc(50% - 5px)', margin: '0 5px' }} className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white">AGREGAR ADMIN</button>
                                                            )}
                                                            {u.isModStudio ? (
                                                            <button onClick={() => handleRemoveModStu(u.email)} style={{ width: 'calc(50% - 5px)', margin: '0 5px' }} className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-warning font-xsssss fw-700 ls-lg text-white">QUITAR MODERADOR</button>
                                                            ) : (
                                                            <button onClick={() => handleAddModStu(u.email)} style={{ width: 'calc(50% - 5px)', margin: '0 5px' }}  className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white">AGREGAR MODERADOR</button>
                                                            )}
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                        
                </div>
            </div>
         </div>
         )}
         </div>
         
         </div>
         </div>
        <Appfooter />
        </section>
        </view>
    )
}

export default Admins
