import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Appfooter from '../components/Appfooter';
import { useAuth0 } from "@auth0/auth0-react";
import Header from '../components/Header';
import { Link } from "react-router-dom";

const Notificationpick = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState(0);
  const [tickers, setTickers] = useState([]);
  const [userTickers, setUserTickers] = useState([]);

  const { user } = useAuth0();
  const { email } = user;
  //console.log(email + "EMAIL")
  const USERURL = "https://dazapis.herokuapp.com/get_sub_type"
  const USERURL1 = "https://dazapis.herokuapp.com/get_all_alerts"
  const USERURL2 = "https://dazapis.herokuapp.com/get_sub_amount"
  const USERURL3 = "https://dazapis.herokuapp.com/unsuscribe"
  const USERURL4 = "https://dazapis.herokuapp.com/suscribe"

  useEffect(() => {
    const params = new URLSearchParams({ 'nombre_usuario': email });

    axios.get(`${USERURL}?${params.toString()}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    })
    .then(res => {
            if (new Date(res.data.fechacorte) >= new Date()) {
              setIsSubscribed(true);
              setSubscriptionType(res.data.suscription);
            }
          });

    axios.get(USERURL1)
      .then(res => {
        //console.log(res.data);  // log the returned data
        // let tickers = res.data.map(obj => obj.ticker); // Remove this
        setTickers(res.data);  // directly set res.data to tickers
      })
      .catch(error => {
        console.error(error);
      });


      axios.get(`${USERURL2}?${params.toString()}`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      })
      .then(res => {
        setUserTickers(res.data.tickers);
      });
    
  }, [email]);

  const canFollow = (ticker) => {
    if (!isSubscribed || subscriptionType === 0) return false;
    if ([465, 884].includes(subscriptionType)) return true;
    if ([374, 985, 23].includes(subscriptionType) && userTickers.length < 1) return true;
    if ([374, 985, 23].includes(subscriptionType) && userTickers.includes(ticker)) return true;
    if ([374, 985, 23].includes(subscriptionType) && userTickers.length >= 1) return false;
    return false;
};

  function handleSubscription(ticker) {
    if (userTickers.includes(ticker)) {
      axios.delete(USERURL3, {
        data: {
          email: email,
          ticker: ticker
        },
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      })
      .then(response => {
        //console.log(response.data);
        
        // Here, update the state to remove the ticker
        setUserTickers(prevTickers => prevTickers.filter(t => t !== ticker));
        
      })
      .catch(error => {
        console.error(error);
      });
    } else {
      axios.post(USERURL4, {
        email: email,
        ticker: ticker
      }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      })
      .then(response => {
        //console.log(response.data);
  
        // Here, update the state to add the ticker
        setUserTickers(prevTickers => [...prevTickers, ticker]);
        
      })
      .catch(error => {
        console.error(error);
      });
    }
  }

  const renderSubscriptionMessage = () => {
    if (subscriptionType === 0) {
        return <div class="d-flex align-items-center">
        <Link to="/paquetes" class="bg-current form-control text-center text-white font-xsss fw-500 p-1 mb-3 w500 rounded-3 d-inline-block align-self-center">
                <p>No cuentas con una suscripción activa. Suscribirse</p>
            </Link>
        </div>;
    } else if ([23, 985, 374].includes(subscriptionType) && userTickers.length >= 1) {
        return <div class="d-flex align-items-center">
       <div class="bg-current form-control text-center text-white font-xsss fw-500 p-1 mb-3 w500 rounded-3 d-inline-block align-self-center">
                <p>Tu suscripción activa solo permite suscribirte a una moneda a la vez</p>
                </div> 
        </div>;
    }
    return null; // Default case
};
  


  return (
    <section>
       <Header></Header>
    <div className="main-content  pt-7 right-chat-active">
        <div className="middle-sidebar-bottom">
            <div class="middle-sidebar-left">
              <div class="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                    
              <div className="card-body p-lg-5 p-4 w-100 border-0">
                <div className="row">
                 <div className="col-lg-12">
                  <h4 className="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs">Monedas disponibles</h4>
                  <p className="mb-4 font-sm fw-400 mont-font text-warning">{renderSubscriptionMessage()}</p> {/* This will render the message based on subscription type and tickers followed */}
                  <div className="container">
                    <div className="row">
                      {tickers.map(ticker => (
                          <div key={ticker} className="col-md-4 col-sm-6 pe-2 ps-2">
                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                              <div className="card-body d-block w-100 p-4 text-center">
                                {/*
                                <figure className="avatar ms-auto me-auto mb-0 position-relative w90 z-index-1">
                                  <img src="assets/images/user-1.png" alt="avater" className="float-right p-1 bg-white rounded-circle w-100"/>
                                </figure>*/}
                                <div className="clearfix"></div>
                                <h4 className="fw-700 font-xss mt-3 mb-0">{ticker}</h4>
                                  <button  
                                    className={`mt-4 p-0 btn p-2 lh-24 w150 ms-1 ls-3 d-inline-block rounded-xl font-xsssss fw-700 ls-lg text-white ${userTickers.includes(ticker) ? 'bg-green' : 'bg-current'}`} 
                                    disabled={!canFollow(ticker)}
                                    onClick={() => handleSubscription(ticker)}>
                                    {userTickers.includes(ticker) ? 'DEJAR DE SEGUIR' : 'SEGUIR'}
                                </button>

                              </div>
                            </div>
                          </div>
                        ))}
       </div>
    </div>  
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>

  </div>

  </div>
        <Appfooter />
    </section>
  );
}

export default Notificationpick;
