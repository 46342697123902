import React, {  Fragment, useState, useEffect } from "react";
import Header from "../components/Header";
import Appfooter from "../components/Appfooter";
import { Link} from "react-router-dom";
import { Button } from "react-bootstrap";



const Cart = () => {


  const [sessionDataPrice, setPrice] = useState(sessionStorage.getItem("price"));
  const [sessionDataSuscription, setSubscription] = useState(sessionStorage.getItem("subscription"));


    useEffect(() => {
      setPrice(sessionStorage.getItem("price"));
      setSubscription(sessionStorage.getItem("subscription"));
    }, []);

    const handleLinkClick = () => {
      const data = {
        price: "0",
        subscription: "No tiene ninguna suscripción seleccionada",
      };
          
      // Save to sessionStorage
      sessionStorage.setItem('price', data.price);
      sessionStorage.setItem('subscription', data.subscription);

      setPrice(data.price)
      setSubscription(data.subscription)
    };

    return (
      <Fragment>
        <Header />


        <div className="main-content  pt-7 right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
              <div class="row">
                <div class="col-lg-12 mb-3">
                  <div class="card p-md-5 p-4 bg-primary-gradiant rounded-3 shadow-xss bg-pattern border-0 overflow-hidden">
                    <div class="bg-pattern-div"></div>
                    <h2 class="display2-size display2-md-size fw-700 text-white mb-0 mt-0">Carrito de compras </h2>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-8 mb-3">
                  <div class="table-content table-responsive">
                    <table class="table text-center">
                      <thead class="bg-greyblue rounded-3">
                        <tr>
                          <th class="border-0 p-4">&nbsp;</th>
                          <th class="border-0 p-4 text-left">Producto</th>
                          <th class="border-0 p-4">Precio</th>
                          <th class="border-0 p-4">Cantidad</th>
                          <th class="border-0 p-4">Total</th>
                          <th class="border-0 p-4">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="product-thumbnail text-left ps-0">
                          </td>
                          <td class="product-headline text-left wide-column">
                            <h3>
                              <a href="/cart" class="text-grey-600 fw-600 font-xsss">Suscripción: {sessionDataSuscription} </a>
                            </h3>
                          </td>
                          <td class="product-p"><span class="product-price-wrapper">
                            <span class="money text-grey-500 fw-600 font-xsss">
                              <span class="font-xsssss">$</span> {sessionDataPrice} </span>
                            </span>
                          </td>
                          <td class="product-p ">
                            
                          <span class="product-price-wrapper">
                            <span class="mone fw-600 font-xsss">
                               1</span>
                            </span>
                            
                          </td>
                          <td class="product-total-price">
                            <span class="product-price-wrapper">
                              <span class="money fmont"><strong>
                              <span class="font-xsssss">$ </span>{sessionDataPrice}</strong>
                            </span>
                            </span>
                          </td>
                          <td class="product-remove text-right">
                            <Button
                            className="bg-white border-white"
                              onClick={handleLinkClick}
                              >
                              <i class="ti-trash font-xs text-grey-500"></i>
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <Link to={"/paquetes"} 
                    class="update-cart bg-dark float-right text-white fw-600 text-uppercase font-xssss border-dark border rounded-3 border-size-md d-inline-block w175 p-3 text-center ls-3">
                      Cambiar producto</Link>
                  </div>
                  <div class="col-lg-4">
                          <div class="checkout-payment card border-0 mb-3 bg-greyblue p-4">
                            <div class="cart-totals">
                              <h4 class="mont-font fw-600 font-md mb-5">Total del carrito</h4>
                              <div class="table-content table-responsive">
                                <table class="table order-table">
                                  <tbody>
                                    <tr class="order-total">
                                      <td class="font-xsss pt-2 fw-600">Total</td>
                                      <td class="font-xssss fw-700 text-grey-600 pt-2 text-right">
                                        <span class="product-price-wrapper">
                                          <span class="money fmont">${sessionDataPrice}</span>
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <Link
                              activeClassName="active"
                              to={{
                                pathname: sessionDataSuscription !== "No tiene ninguna suscripción seleccionada" ? "/checkout" : "/cart",
                                
                            }}
                            className={`bg-dark float-right fw-600 rounded-3 border-size-md w-100 p-1 ${sessionDataSuscription === "No tiene ninguna suscripción seleccionada" ? "disabled" : ""}`}
                            >
                              <button 
                                className="bg-dark float-right text-white fw-600 text-uppercase font-xsss border-dark border mt-3 d-inline-block w-100 text-center" 
                                disabled={sessionDataSuscription === "No tiene ninguna suscripción seleccionada"}
                              >
                                <p>Proceder a comprar</p>
                              </button>
                            </Link>
                          
                        </div>
                        </div>
                        </div>

                    
            
          </div>
        </div>

      </Fragment>
    );
  }


export default Cart;
